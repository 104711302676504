import BaseGateway from './BaseGateway';
import UserServiceRequest from './requests/UserServiceRequest';
import PortalServiceRequest from "Core/api/requests/PortalServiceRequest";

/**
 * User gateway
 */
export default class UserGateway extends BaseGateway {
    /**
     * Get authenticated user
     *
     * @param {[]} includes
     * @return {Promise<*>}
     */
    getAuthenticatedUser(includes = []) {
        return this.request()
            .withIncludes(includes)
            .get('user/profile');
    }

    /**
     * Send forgot password request
     *
     * @param username
     * @return {Promise<*>}
     */
    forgotPassword (username) {
        return this.usingRequest(new PortalServiceRequest())
            .withBody({
                username: username,
                relative_reset_url: 'reset-password'
            })
            .post('password-forgot');
    }

    /**
     * Reset password of a user
     *
     * @param username
     * @param token
     * @param password
     * @return {Promise<*>}
     */
    resetPassword (username, token, password) {
        return this.request()
            .withBody({ username, token, password })
            .post('password/reset');
    }

    /**
     * Setup password for user with invitation token
     *
     * @param token
     * @param password
     * @return {Promise<*>}
     */
    setupPassword (token, password) {
        return this.request()
            .withBody({ token, password })
            .post('password/setup')
    }

    /**
     * New user request object
     *
     * @return {UserServiceRequest}
     */
    makeRequest() {
        return new UserServiceRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {UserGateway}
 */
export const userGateway = new UserGateway();
