import InteractsWithPageTitle from 'Portal/mixins/InteractsWithPageTitle';

/**
 * Setup page title
 *
 * @param to
 * @return {*}
 */
export default ({ to }) => {
    const page = to.meta.page;

    if (page && page.settings_map['browser-title']) {
        InteractsWithPageTitle.methods.setPageTitle(
            page.settings_map['browser-title']
        );
    }
}