import BaseGateway from 'Core/api/BaseGateway';
import PortalServiceRequest from "Core/api/requests/PortalServiceRequest";

export default class PortalGateway extends BaseGateway {
  index() {
    return this.request().get('portal');
  }
  
  /**
   * Get portal primary language
   *
   * @return {Promise<*>}
   */
  getPrimaryLanguage () {
    return this.request().withoutHeaders().get('');
  }
  
  /**
   * Deactivate own portal
   *
   * @param personId
   * @return {Promise<*>}
   */
  deactivate(personId) {
    return this.request().delete(`persons/${personId}/portal/deactivate`);
  }

  /**
   * New http request
   *
   * @return {PortalServiceRequest}
   */
  makeRequest() {
    return new PortalServiceRequest();
  }
}

/**
 * Portal gateway
 *
 * @type {PortalGateway}
 */
export const portalGateway = new PortalGateway();