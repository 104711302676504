<script>
    import BaseFormatter from 'Core/components/data/formatters/BaseFormatter';
    import Formatter from 'phone-formatter';

    export default {
        /**
         * Component name.
         */
        name: 'PhoneFormatter',

        /**
         * Extend base component
         */
        extends: BaseFormatter,

        /**
         * Computed properties
         */
        props: {
            /**
             * The format of the phone number
             */
            format: {
                type: String,
                default () {
                    return 'NNNNNNNNNNNNNNN';
                },
            },
        },

        /**
         * Computed properties.
         */
        computed: {
            /**
             * Formatted value.
             *
             * @returns {string}
             */
            formatted () {
                const formatted =  Formatter.format(this.value, this.format, { normalize: true });

                return formatted.replaceAll('N', '');
            },
        },
    }
</script>