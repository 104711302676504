/**
 * Empty string const
 *
 * @type {string}
 */
export const STRING_EMPTY = "";

/**
 * Generates random string
 *
 * @return {string}
 */
export const randomString = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

/**
 * Workaround for ordering groups in full calendar
 *
 * So if you are grouping resources in full calendar and then want to order
 * by it's group order number you might to hold of, because full calendar
 * does not 'track' when order number is changed so that's why this
 * function exists. It is basically generate a string which
 * will be 'eaten' by the calendar and once the ordering
 * changes the calendar will notice it and rearrange
 * groups in order you specified
 *
 * @param order
 * @param salt
 * @return {string}
 */
export const generateFullCalendarOrder = (order, salt) => `${order}_full-calendar_${+salt}`;

/**
 * Capitalize first letter
 *
 * @param string
 * @return {string}
 */
export const capitalizeFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Check is var is a string
 *
 * @param string
 * @return {boolean}
 */
export const isString = (string) => typeof string === 'string';

/**
 * Return empty string in case of falsy value
 *
 * @param string
 * @return {string}
 */
export const stringOrEmpty = (string) => string || STRING_EMPTY;

/**
 * Check whether string is empty
 *
 * @param string
 * @return {boolean}
 */
export const isEmptyString = (string) => string === STRING_EMPTY;

/**
 * Interpolate string
 *
 * @param string
 * @param replace
 *
 * @return {string}
 */
export const interpolate = (string, replace) => {
    let result = string;

    // Loop through them and replace all key value pairs.
    for (let name in replace) {
        const regex = new RegExp(':' + name, 'g');
        result = result.replace(regex, replace[name]);
    }

    return result;
}

/**
 * Convert int to string with shorten number format
 *
 * @param value
 * @return {*}
 */
export const intToShortenString = (value) => {
    const suffixes = ["", "k", "m", "b","t"];
    const suffixNum = Math.floor((""+value).length/3);
    let shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000,suffixNum)) : value).toPrecision(2));

    if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue+suffixes[suffixNum];
}