<template>
  <router-link
    :to="to"
    exact
    tag="li"
    class="two-solar-navigation__item"
  >
    <span
      class="h-full w-full py-5 px-3 "
      @click="click"
    >
      <HeaderText class="truncate">
        {{ title }}
      </HeaderText>
    </span>
  </router-link>
</template>

<script>
import HeaderMenuItem from 'Portal/components/headers/HeaderMenuItem';

export default {
  name: "NavigationItem",

  extends: HeaderMenuItem,

  methods: {
    click(input) {
      this.$emit('navigation-item-select', input);
    }
  },
}
</script>

<style type="text/scss" lang="scss" scoped>
.two-solar-navigation__item {
  @apply border-b border-gray-200 flex items-center cursor-pointer;

  &:first-of-type {
    @apply border-t;
  }

  height: 63px;
}
</style>
