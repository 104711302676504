import axios from "axios";
import ExchangeTokenInterceptor from "Core/api/interceptors/ExchangeTokenInterceptor";
import UnauthenticatedInterceptor from "Core/api/interceptors/UnauthenticatedInterceptor";

export default (vm) => {
    const exchangeTokenInterceptor = new ExchangeTokenInterceptor(vm);
    const unauthenticatedInterceptor = new UnauthenticatedInterceptor(vm);
    
    axios.interceptors.request.use((request) => exchangeTokenInterceptor.onFulfilled(request));
    axios.interceptors.response.use(null, (error) => unauthenticatedInterceptor.onRejected(error));
}