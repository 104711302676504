export default class Preferences {
    static get COOKIE_USAGE_ACCEPTED () {
        return 'COOKIE_USAGE_ACCEPTED';
    }

    static get SMS_NOTIFICATION () {
        return 'SMS_NOTIFICATION';
    }

    static get EMAIL_NOTIFICATION () {
        return 'EMAIL_NOTIFICATION';
    }

    static get COOKIE_USAGE_OBLIGATED () {
        return 'COOKIE_USAGE_OBLIGATED';
    }

    static get COOKIE_USAGE_FUNCTIONAL () {
        return 'COOKIE_USAGE_FUNCTIONAL';
    }

    static get COOKIE_USAGE_ANALYTICAL () {
        return 'COOKIE_USAGE_ANALYTICAL';
    }
}
