import flatten from 'lodash/flatten';

export default class FontsLoader {
    constructor (fonts) {
        this.fonts = fonts;
    }
    
    /**
     * @return {Promise<any>}
     */
    load () {
        const loadingPromises = this.fonts.map((font) => this.loadFont(font))

        return Promise.all(loadingPromises).then((fonts) => {
            flatten(fonts).forEach(font => document.fonts.add(font));
            
            return fonts;
        });
    }
    
    /**
     * @param config Font config from backend
     *
     * @private
     */
    loadFont ({ family, style }) {
        const subFonts = style.map((style) => {
            const descriptors = style.descriptors || {};
            const font = new FontFace(family, `url(${style.link})`, descriptors);
    
            return font.load();
        })
        
        return Promise.all(subFonts);
    }
}