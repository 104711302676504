<template>
  <img
    :src="url"
    alt="Error"
  >
</template>

<script>
import BaseLogo from "Portal/components/images/BaseLogo";
import DefaultErrorImage from '@/assets/containers/portal/images/request/request-lost.svg';

export default {
    name: "ErrorImage",
    
    extends: BaseLogo,
    
    computed: {
        url () {
            const definition = this.styleGuideSchema.get('error-image');

            if (! definition) {
                return DefaultErrorImage;
            }

            return definition.getUrl() || DefaultErrorImage;
        },
    },
}
</script>

<style scoped>

</style>