export const socketSend = (socket, obj) => {
	waitForSocketConnection(socket, () => {
		socket.send(obj);
	})
}

const waitForSocketConnection = (socket, cb) => {
	setTimeout(() => {
		if (socket.readyState === 1) {
			if (cb !== null) {
				cb();
			}
		} else {
			waitForSocketConnection(socket, cb)
		}
	}, 5);
}
