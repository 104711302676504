import routes from "./routes";
import VueRouter from "vue-router";
import Vue from "vue";
import { scrollTo } from './scrollTo';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

// Set up middleware for router
// setupMiddleware(router);
scrollTo(router)

export default router;
