export default {
    /**
     * Mixin methods
     */
    methods: {
        /**
         * Custom page redirect
         *
         * @param route
         * @param routeParams
         */
        $pagePush ({ route, routeParams }) {
            this.$router.push({
                ...route,
                params: routeParams
            }).then();
        },
    }
}