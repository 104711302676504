import portal from '../../containers/portal/locale';
import core from './core';

export default {
    en: {
        portal: portal.en,
        core: core.en,
    },
    nl: {
        portal: portal.nl,
        core: core.nl
    },
    de: {
        portal: portal.de,
        core: core.de
    },
    es: {
        portal: portal.es,
        core: core.es,
    },
};