import { HttpErrorHandler } from '../services/HttpErrorHandler';

const DEFAULT_RESOLVE_OPTIONS = {
    /*
     * Custom error handlers
     */
    handlers: {},
};

export default {
    /**
     * Available methods.
     */
    methods: {
        /**
         * Resolve promise and handle it's errors
         *
         * @param promise
         * @param options
         * @return {Promise<any>}
         */
        $resolve (promise, options = {}) {
            options = Object.assign({}, DEFAULT_RESOLVE_OPTIONS, options);

            return promise.catch(e => {
                const rejected = Promise.reject(e);

                if (e?.response) {
                    this.$handleHttpErrors(e, options);
                }

                // ...you may register here more error handlers...

                return rejected;
            });
        },

        /**
         * Handle validation errors received from the server.
         *
         * @param e
         * @param options
         */
        $handleHttpErrors (e, options) {
            const response = e.response;

            if (! response.data) {
                return;
            }

            const handler = new HttpErrorHandler(
                this,
                response.status,
                response.data,
                response.headers
            );

            handler.invoke(options.handlers);
        },
    },
};
