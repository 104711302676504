import Amplitude from 'amplitude-js';
import services from 'Core/config/services';
import Cookie from 'js-cookie';
import startsWith from 'lodash/startsWith';

/**
 * Remove amplitude cookies
 */
const removeCookies = () => {
    const cookies = Cookie.get();

    // Remove cookies that starts with amp_
    Object.keys(cookies)
        .filter((name) => startsWith(name, 'amp_'))
        .map(Cookie.remove);
}

export default class EventLogger {
    /**
     * Constructor
     */
    constructor (apiKey = services.amplitude.apiKey, instanceName = '') {
        this.instance = Amplitude.getInstance(instanceName);

        this.isInitialized = false;
        this.pendingLogs = [];
        this.apiKey = apiKey;
    }

    /**
     * Init
     *
     * @param disableCookies
     */
    init (disableCookies) {
        this.instance.init(this.apiKey, null, {
            disableCookies,
        }, () => {
            this.isInitialized = true;

            this._logPendingLogs();
        });

        // In case user disable cookie usage, remove it
        if (disableCookies) {
            removeCookies();
        }
    }

    /**
     * Log event to the analytics service
     *
     * @param event
     * @param payload
     */
    log (event, payload) {
        if (! this.isInitialized) {
            this.pendingLogs.push([event, payload]);

            return;
        }

        this.instance.logEvent(event, payload);
    }

    /**
     * Se unique user identifier
     *
     * @param id
     */
    setUserIdentifier (id) {
        this.instance.setUserId(id);
    }

    /**
     * Log pending logs to the amplitude
     *
     * @private
     */
    _logPendingLogs () {
        this.pendingLogs.map((args) => this.log.apply(this, args));
    }
}

/**
 * Instance of the event login
 *
 * @type {EventLogger}
 */
export const logger = new EventLogger();