import camelCase from 'lodash/camelCase';
import mapValues from 'lodash/mapValues';

/**
 * Create type for async action.
 *
 * @param name
 */
export const createType = (name) => {
    return {
        pending: `${name}_PENDING`,
        success: `${name}_SUCCESS`,
        failure: `${name}_FAILURE`,
        pendingKey: camelCase(`${name}_PENDING`),
    };
};

/**
 * Create state for async action.
 *
 * @param types
 */
export const createAsyncState = (types) => {
    const state = {};

    types.forEach(type => {
        state[type.pendingKey] = false;
    });

    return state;
};

/**
 * Create async action.
 *
 * @param type
 * @param request
 */
export const createAsyncAction = (type, request) => {
    return (state, payload) => {
        state.commit(type.pending);

        return new Promise((resolve, reject) => {
            request(state, payload)
                .then(response => {
                    state.commit(type.success, response);
                    resolve(response);
                })
                .catch(response => {
                    state.commit(type.failure, response);
                    reject(response);
                });
        });
    };
};

/**
 * Helper to simplify working with the default loading.
 *
 * @param type
 * @param pending
 * @param success
 * @param failure
 * @returns {{}}
 */
export const createAsyncMutation = (type, { pending, success, failure } = {}) => {
    const types = {
        [type.pending]: {
            pending: true,
            handler: pending,
        },

        [type.success]: {
            pending: false,
            handler: success,
        },

        [type.failure]: {
            pending: false,
            handler: failure,
        },
    };

    return mapValues(types, ({ pending, handler }) => {
        return (state, payload) => {
            if (handler && handler instanceof Function) {
                handler(state, payload);
            }

            state[type.pendingKey] = pending;
        };
    });
};
