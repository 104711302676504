import Query from '../../services/query/Query';
import axios from 'axios';
import {STRING_EMPTY} from 'Core/utils/string';

export default class Request {
    /**
     * Constructor
     *
     * @param baseUrl
     * @param params default params
     * @param body
     * @param includes
     * @param headers
     */
    constructor(baseUrl, params = {}, body = {}, includes = [], headers = {}) {
        this.params = {};
        this.body = {};
        this.includes = [];
        this.headers = {};

        this.withBaseUrl(baseUrl);
        this.withParams(params);
        this.withBody(body);
        this.withIncludes(includes);
        this.withHeaders(headers);
    }

    /**
     * Http get method
     *
     * @param url
     * @return {Promise<*>}
     */
    get (url) {
        return axios.get(this.buildUri(url), this.getRequestConfig());
    }

    /**
     * Http post method
     *
     * @param url
     * @return {Promise<*>}
     */
    post (url) {
        return axios.post(this.buildUri(url), this.body, this.getRequestConfig());
    }

    /**
     * Http put method
     *
     * @param url
     * @return {Promise<*>}
     */
    put (url) {
        return axios.put(this.buildUri(url), this.body, this.getRequestConfig());
    }

    /**
     * Http patch method
     *
     * @param url
     * @return {Promise<*>}
     */
    patch (url) {
        return axios.patch(this.buildUri(url), this.body, this.getRequestConfig());
    }

    /**
     * Http delete method
     *
     * @param url
     * @return {Promise<*>}
     */
    delete (url) {
        return axios.delete(this.buildUri(url), this.getRequestConfig());
    }

    /**
     * Set base url
     *
     * @param baseUrl
     * @return {Request}
     */
    withBaseUrl (baseUrl) {
        this.baseUrl = baseUrl;

        return this;
    }

    /**
     * Add additional params
     *
     * @param params
     * @return {Request}
     */
    withParams (params = {}) {
        Object.assign(this.params, params);

        return this;
    }

    /**
     * Add additional includes
     *
     * withIncludes(['user.roles'])
     * ->
     * ?include=user.roles
     *
     * @param includes
     * @return {Request}
     */
    withIncludes (includes = []) {
        this.includes = this.includes.concat(includes);

        return this
    }

    /**
     * Add additional headers
     *
     * @param headers
     * @return {Request}
     */
    withHeaders (headers = {}) {
        Object.assign(this.headers, headers);

        return this;
    }

    /**
     * Add additional body params
     *
     * @param body
     * @return {Request}
     */
    withBody (body = {}) {
        Object.assign(this.body, body);

        return this;
    }

    /**
     * Attach binary payload
     *
     * @param {Blob} blob
     * @return {Request}
     */
    withBlob (blob) {
        this.body = blob;

        this.withHeaders({
            'Content-Type': blob.type
        });

        return this;
    }

    /**
     * With form data body
     *
     * @param {FormData} form
     * @return {Request}
     */
    withForm (form) {
        this.body = form;

        this.withHeaders({
            'Content-Type': 'multipart/form-data',
        });

        return this;
    }

    /**
     * Make request without custom headers
     *
     * @return {Request}
     */
    withoutHeaders (blackList = []) {
        blackList.forEach((headerName) => {
            delete this.headers[headerName];
        });
      
        if (blackList.length === 0) {
            this.headers = {};
        }

        return this;
    }

    /**
     * Get config for request
     *
     * @return {{headers: ({}|Headers)}}
     */
    getRequestConfig () {
        return {
            headers: this.headers
        };
    }

    /**
     * Build uri for url
     *
     * @param url
     * @return {string}
     */
    buildUri (url) {
        url = url || STRING_EMPTY;

        const query = new Query(this.baseUrl + url);
        query.addParams(this.params);

        if (this.includes.length > 0) {
            query.add('include', this.includes.join(','));
        }

        return query.toString();
    }
}