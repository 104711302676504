import BaseGateway from './BaseGateway';
import AuthorizerRequest from "Core/api/requests/AuthorizerRequest";

/**
 * AuthorizerGateway
 */
export default class AuthorizerGateway extends BaseGateway {
    /**
     * Login user that has magic link
     *
     * @param token
     * @return {Promise<*>}
     */
    loginViaMagicLink (token) {
        return this.request().withoutHeaders().withBody({ token }).post('magic-login')
    }

    /**
     * New user request object
     *
     * @return {AuthorizerRequest}
     */
    makeRequest() {
        return new AuthorizerRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {AuthorizerGateway}
 */
export const authorizerGateway = new AuthorizerGateway();
