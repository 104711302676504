import { tap } from '../utils/function';
import translator from 'Core/services/translations/instance';
import { getTimezone } from "Core/utils/date";

/**
 * Default headers
 *
 * @type {{}}
 */
const defaultHeaders = {
  'Accept-Language': translator.locale,
  'Time-Zone': getTimezone(),
};

/**
 * Base gateway
 */
export default class BaseGateway {
  /**
   * Add default headers of gateways
   *
   * @param key
   * @param value
   */
  static addDefaultHeader(key, value) {
    Object.assign(defaultHeaders, {
      [ key ]: value,
    });
  }
  
  /**
   * Get default headers
   *
   * @return {{}}
   */
  static getDefaultHeaders() {
    return defaultHeaders;
  }
  
  /**
   * New request
   *
   * @return {Request}
   */
  request() {
    return this.usingRequest(this.makeRequest());
  }
  
  usingRequest(request) {
    return tap(request, (request) => {
      request.withHeaders(BaseGateway.getDefaultHeaders());
    });
  }
  
  /**
   * Make new request object
   *
   * @return {Request}
   */
  makeRequest() {
    throw new Error('Not implemented');
  }
}
