import Vue from 'vue'
import services from '../../config/services';
import * as Sentry from "@sentry/vue";
import {isDevelopmentEnv} from "Core/utils/function";

if (!isDevelopmentEnv()) {
    Sentry.init({
        dsn: services.sentry.dsn,
        Vue,
    });
}