import { render, staticRenderFns } from "./Heading4.vue?vue&type=template&id=38548f36&scoped=true&"
import script from "./Heading4.vue?vue&type=script&lang=js&"
export * from "./Heading4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38548f36",
  null
  
)

export default component.exports