import { logger } from 'Core/services/analytics/EventLogger';

let previousPath = null;

const logEvent = (to) => {
    const title = to.meta.page?.settings_map['browser-title'];
    
    logger.log('page_visited:' + to.name, {
        name: to.name,
        params: to.params,
        extra: { title }
    });
}

/**
 * Log how user travel though in application pages
 *
 * @param next
 * @param store
 * @param to
 * @param from
 * @return {*}
 */
export default ({ store, to, from }) => {
    if (
        to.path !== previousPath &&
        store.getters['core/user/hasUser'] &&
        from.fullPath !== to.fullPath
    ) {
        logEvent(to);
    }

    previousPath = to.path;
}