import Vue from 'vue'
import MiddlewarePlugin from 'vue-router-middleware-plugin'
import router from 'Core/router';
import store from 'Core/store';
import title from 'Portal/router/middleware/title';

const middleware = [
    title,
];

Vue.use(MiddlewarePlugin, {
    router,
    middleware,
    context: { store }
})