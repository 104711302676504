/**
 * This class handles money formatting.
 */
export default class Money {
    /**
     * Constructor.
     *
     * @param translations
     * @param locale
     */
    constructor (locale) {
        this.locale = locale;

        this.formatter = new Intl.NumberFormat(locale);
    }

    /**
     * Format the value
     *
     * @param value
     * @returns {string|*}
     */
    format (value, withCurrencyIcon = true) {
        if (Number.isNaN(Number.parseFloat(value))) {
            return value;
        }

        let amount = Number(value);

        let string = this.formatter.format(amount.toFixed(2));

        if (withCurrencyIcon) {
            string = this.getCurrencyIcon() + string;
        }

        return string;
    }

    /**
     * @returns {string}
     */
    getCurrencyIcon () {
        // We can add checks here for different locales

        return '€ ';
    }
}