import { createAsyncMutation, createAsyncAction, createAsyncState, createType } from 'Core/utils/store';
import { offerGateway } from 'Core/api/OfferGateway';
import OfferStatus from "Portal/models/OfferStatus";

const types = {
  FETCH_OFFERS: createType("FETCH_OFFERS"),
  GET_OFFER: createType("GET_OFFER"),
  UPDATE_OFFER_LEGACY: createType("UPDATE_OFFER_LEGACY"),
  UPDATE_OFFER: createType("UPDATE_OFFER"),
  SIGN_OFFER: createType("SIGN_OFFER"),
  REJECT_OFFER: createType("REJECT_OFFER"),
  HANDLE_SIGNED_OFFER: 'HANDLE_SIGNED_OFFER',
  SELECTED_COMPARE_OFFERS: 'SELECTED_COMPARE_OFFERS'
};

const state = {
  offers: [],
  offer: {},
  isSigned: false,
  signedOffer: null,
  signStatus: null,
  preselectedOffer: null,
  selectedCompareOffers: [],

  ...createAsyncState([
    types.FETCH_OFFERS,
  ]),
};

const getters = {
  offers: ({ offers }) => offers,
  offer: ({ offer }) => offer,
  signedOffer: ({ signedOffer }) => signedOffer,
  isSigned: ({ isSigned }) => isSigned,
  signStatus: ({ signStatus }) => signStatus,
  isAccepted: (state, { signStatus }) => signStatus === OfferStatus.SIGNED,
  isRejected: (state, { signStatus }) => signStatus === OfferStatus.REJECTED,
  preselectedOffer: ({ preselectedOffer }) => preselectedOffer,
  preselectedOfferId: ({ preselectedOffer }) => preselectedOffer?.id,
  fetchOffersPending: ({ fetchOffersPending }) => fetchOffersPending,
  selectedCompareOffers: ({ selectedCompareOffers }) => selectedCompareOffers,
};

const actions = {
  fetchOffers: createAsyncAction(types.FETCH_OFFERS, fetchOffers),
  getOfferById: createAsyncAction(types.GET_OFFER, getOfferById),
  updateOfferLegacy: createAsyncAction(types.UPDATE_OFFER_LEGACY, updateOfferLegacy),
  updateOffer: createAsyncAction(types.UPDATE_OFFER, updateOffer),
  signOffer: signOffer,
  rejectOffer: createAsyncAction(types.REJECT_OFFER, rejectOffer),
};

const mutations = {
  ...createAsyncMutation(types.FETCH_OFFERS, {
    success: handleSuccess,
  }),
  ...createAsyncMutation(types.UPDATE_OFFER, {
    success: handleSuccess,
  }),
  ...createAsyncMutation(types.SIGN_OFFER, {
    success: handleSuccess,
  }),
  ...createAsyncMutation(types.REJECT_OFFER, {
    success: handleSuccess,
  }),
  ...createAsyncMutation(types.UPDATE_OFFER_LEGACY),

  [types.HANDLE_SIGNED_OFFER] (state, offer) {
    Object.assign(state, {
      isSigned: true,
      signStatus: offer.state,
      signedOffer: offer,
    });
  },

  setSelectedCompareOffers (state, selectedOffers) {
    state.selectedCompareOffers = selectedOffers;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
  modules: {},
};

function fetchOffers(_, requestId) {
  return Promise.all([
    offerGateway.getOffersByRequestId(requestId),
  ]);
}

function getOfferById(_, offerId) {
  return offerGateway.getOfferById(offerId);
}

function updateOfferLegacy(_, {offerId, body, requestId}) {
  return offerGateway.updateOfferLegacy(offerId, body, requestId);
}

async function updateOffer(_, { requestId, offerId, preselected, products, optional_products }) {
  await offerGateway.updateOffer(offerId, { preselected, products, optional_products });

  return await fetchOffers(_, requestId);
}

async function signOffer(_, { offerId, statusId, signature, signatureName, optionalProducts }) {
  const response = await offerGateway.sign(offerId, {
    status_id: statusId,
    signature_name: signatureName,
    signature,
    optional_products: optionalProducts,
  });

  _.commit(types.HANDLE_SIGNED_OFFER, response.data);
}

async function rejectOffer(_, { offerId, reason, statusId, signature, signatureName }) {
  const response = await offerGateway.reject(offerId, {
    reason,
    signature,
    status_id: statusId,
    signature_name: signatureName,
  });

  _.commit(types.HANDLE_SIGNED_OFFER, response.data);
}

// HELPERS

function handleSuccess(state, [offers]) {
  const signedOffer = offers.find(offer => offer.state === OfferStatus.SIGNED || offer.state === OfferStatus.REJECTED);
  const preselectedOffer = offers.find(({ preselected }) => preselected);

  return Object.assign(state, {
    offers,
    isSigned: !!signedOffer,
    signStatus: signedOffer?.state || null,
    signedOffer: !!signedOffer ? signedOffer : preselectedOffer,
    preselectedOffer: !!signedOffer ? null : preselectedOffer,
  });
}
