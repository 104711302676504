<template>
  <div class="missing-data-container">
    <div class="missing-data-container__image-wrap">
      <error-image
        v-if="!hiddenImage"
        class="w-full"
      />
    </div>

    <div
      class="missing-data-container__content-wrap"
      :class="{ '-mt-10': hiddenImage }"
    >
      <div class="missing-data-container__title">
        <slot name="title" />
      </div>
      <div class="missing-data-container__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
    import ErrorImage from "Portal/components/images/ErrorImage";

    export default {
        /**
         * Component name
         */
        name: "MissingDataContainer",

        components: {ErrorImage},

        data () {
            return {
                hiddenImage: true, // The error image is temporarily hidden
            }
        }
    }
</script>

<style type="text/scss" lang="scss" scoped>
    .missing-data-container {
        @apply flex flex-col justify-center items-center; //  h-full

        max-width: 776px;

        &__image-wrap {
            @apply w-full;

            padding-bottom: 30px;

            @screen tablet {
                padding-bottom: 40px;
            }

            @screen laptop {
                padding-bottom: 50px;
            }
        }

        @screen tablet {
            &__content-wrap {
                padding-left: 23px;
                padding-right: 23px;
            }
        }

        @screen laptop {
            &__content-wrap {
                padding-left: 38px;
                padding-right: 38px;
            }
        }

        &__title {
            * {
                @apply text-center;
            }
        }

        &__content {
            @apply pt-5;

            @screen laptop {
                @apply pt-6;
            }

            * {
                @apply text-center;
            }
        }
    }
</style>
