import { Loader } from 'google-maps';
import config from 'Core/config/services';

const loader = new Loader(config.google.maps.apiKey);

class StreetViewImageMeta {
  constructor({ googleMaps, position, pitch = 8 }) {
    this._pitch = pitch;
    this._googleMaps = googleMaps;
    this._position = position;
  }

  async composeImageUrl({size, fov} = { size: '600x600', fov: '75' }) {
    const heading = await this._composeHeading();
    const { streetViewImageMeta: { api, apiKey } } = config.google;

    return `${api}?size=${size}&fov=${fov}&location=${this._position.lat},${this._position.lng}&heading=${heading}&pitch=${this._pitch}&key=${apiKey}`;
  }

  async _composeHeading(panoramaPosition = { lat: this._position.lat, lng: this._position.lng }) {
    const cameraPosition = await this._getStreetViewCameraPosition(this._position);

    return this._googleMaps.geometry.spherical.computeHeading(cameraPosition, panoramaPosition);
  }

  _getStreetViewCameraPosition({ lat, lng }) {
    return new Promise((resolve) => {
      const cb = (e) => {
        const coords = e ? e.location.latLng : {
          lat: () => 0,
          lng: () => 0,
        }

        resolve(coords);
      };

      (new this._googleMaps.StreetViewService()).getPanoramaByLocation(
        new this._googleMaps.LatLng(lat, lng),
        100,
        cb
      );
    });
  }
}

class GoogleMap extends StreetViewImageMeta {
  constructor({ googleMaps, panoramaRef, mapRef, position, pitch }) {
    super({ googleMaps, panoramaRef, position, pitch });

    this.setupGoogleStreetView({ panoramaRef, mapRef });
  }

  setupPanorama(panoramaRef) {
    this._panorama = new this._googleMaps.StreetViewPanorama(panoramaRef, {
      position: this._position,
      disableDefaultUI: true,
    });
  }

  setupGoogleStreetView({ panoramaRef, mapRef }) {
    this.setupPanorama(panoramaRef);

    const map = new this._googleMaps.Map(mapRef, { center: this._position });

    map.setStreetView(this._panorama);
  }

  async pointCameraToTheHouse() {
    const heading = await this._composeHeading(this._panorama.getPosition());

    this._panorama.setPov({ heading, pitch: this._pitch });
  }
}

export default {
  build: async (computeLazyParams) => {
    const google = await loader.load();
    const { type = "GoogleMap", ...params } = computeLazyParams();

    const map = new {
      GoogleMap,
      StreetViewImageMeta,
    }[ type ]({
      googleMaps: google.maps,
      ...params,
    });

    return {
      pointCameraToTheHouse: () => map.pointCameraToTheHouse(),
      composeImageUrl: () => map.composeImageUrl(),
    };
  },
};
