<template>
  <router-view-container>
    <main
      ref="main"
      class="tablet:mt-0 body-content"
      :class="`${$screen.mobile.vendor} ${$screen.mobile.orientation}`.trim()"
    >
      <pages-sidebar />
      <router-view :key="$route.path" />
    </main>
  </router-view-container>
</template>

<script>
import PagesSidebar from 'Portal/components/sidebars/PagesSidebar';
import { mapActions, mapGetters, mapState } from 'vuex'
import RouterViewContainer from 'Portal/components/content/RouterViewContainer';

export default {
  name: "BodyContent",

  components: {
    PagesSidebar,
    RouterViewContainer,
  },

  /* DATA */
  data () {
    return {
      shouldUpdatePreferences: false
    }
  },

  /* COMPUTED */
  computed: {
    ...mapGetters('portal/layout/sidebar', [
      'activeSideBar',
    ]),

    ...mapGetters('core/preferences', [
      'smsNotifications',
      'emailNotifications',
      'cookieUsageObligated',
      'cookieUsageFunctional',
      'cookieUsageAnalytical',
    ]),

    ...mapState('core/preferences', [
      'cookies',
      'notifications',
      'preferences',
      'cookiesAccepted'
    ]),
  },

  /* WATCHERS */
  watch: {
    activeSideBar(state) {
      this.$refs.main.closest('body').style.position = state ? 'fixed' : '';
    },

    '$screen.width'(currWidth) {
      if (currWidth > this.$screen.laptop && this.activeSideBar !== null) {
        this.toggleSidebar({});
      }
    },
  },

  /* METHODS */
  methods: {
    ...mapActions('portal/layout/sidebar', [
      'toggleSidebar',
    ]),
  },
}
</script>
