import BaseGateway from './BaseGateway';
import ClientServiceRequest from './requests/ClientServiceRequest';

/**
 * Request gateway
 */
export default class ClientGateway extends BaseGateway {
    /**
     * Get client of authenticated user
     *
     * @return {Promise<*>}
     */
    getClient () {
        return this.request().get('me/client');
    }

    /**
     * New user request object
     *
     * @return {ClientServiceRequest}
     */
    makeRequest() {
        return new ClientServiceRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {ClientGateway}
 */
export const clientGateway = new ClientGateway();
