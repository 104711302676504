<template>
  <div
    class="heading-4"
    :style="cssVariables"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
    import BaseText from './BaseText';

    export default {
        /**
         * Component name
         */
        name: "Heading4",

        /**
         * Extend base component
         */
        extends: BaseText,

        props: {
            useHeading1Color: {
              type: Boolean,
              default: false
            }
        },

        /**
         * Computed properties
         */
        computed: {
            /**
             * Definition of styles for heading1
             *
             * @return {StyleDefinition|null}
             */
            definition () {
                return this.styleGuideSchema.get('h4');
            },

            cssVariables () {
                if (! this.definition) {
                    return [];
                }

                const heading1Definition = this.styleGuideSchema.get('h1');

                return [
                    this.definition.getBackgroundColor(),
                    ...(this.useHeading1Color ? [heading1Definition.getTextColor()] : [this.definition.getTextColor()]),
                    this.definition.getFontFamily(),
                    this.definition.getFontSize(),
                    this.definition.getFontStyle(),
                ];
            },
        },
    }
</script>

<style scoped>

</style>