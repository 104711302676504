<template>
  <div id="app">
    <template-loader
      v-if="isProcessing"
    />
    <error-page
      v-else-if="isError"
      :error="error"
    />
    <template v-else>
      <router-view name="header" />
      <body-content />
      <router-view name="footer" />
    </template>
    <portal-target name="modal-outlet" />

    <!-- C O O K I E S  M O D A L -->
    <CookiesModal v-if="isModalCookies" />
  </div>
</template>

<script>
import BootstrapAuthenticatedUser from './js/containers/portal/mixins/boot/BootstrapAuthenticatedUser'
import BootstrapRoutes from './js/containers/portal/mixins/boot/BootstrapRoutes'
import Processable from './js/core/mixins/Processable'
import TemplateLoader from 'Portal/components/miscs/TemplateLoader'
import BootstrapPhases from './js/containers/portal/mixins/boot/BootstrapVuex'
import BodyContent from 'Portal/components/content/BodyContent'
import ErrorPage from 'Portal/pages/error/ErrorPage'
import BootstrapPortal from 'Portal/mixins/boot/BootstrapPortal'
import CookiesModal from 'Portal/components/modals/CookiesModal'
import isEmpty from 'lodash/isEmpty'
import HandleErrors from 'Core/mixins/HandleErrors'
import { mapGetters } from 'vuex'
import HandleLogout from 'Portal/mixins/HandleLogout'
import HandleInactivity from 'Portal/mixins/HandleInactivity'
import {
  askNotificationPermission,
  serviceWorkerRegistration,
  addTabsMessageListener,
} from 'Core/utils/sw';
import { socketSend } from 'Core/utils/socket';
import { mapState } from 'vuex'
import MagicLink from "Portal/mixins/boot/MagicLink";
import * as Sentry from '@sentry/browser';

// The object of the initial route
// that in the application when
// it's start
const INITIAL_ROUTE = {
  fullPath: '/',
  hash: '',
  matched: [],
  meta: {},
  name: null,
  params: {},
  path: '/',
  query: {},
}

const PERSONALIZATION_SOCKET_ACTIONS = {
  SUBSCRIBE: 'subscribeAction',
}

export default {
  /**
   * Component name
   */
  name: 'App',

  /**
   * Component
   */
  components: {
    ErrorPage,
    BodyContent,
    TemplateLoader,
    CookiesModal
  },

  /**
   * Component mixins
   */
  mixins: [
    Processable,
    BootstrapPhases,
    BootstrapRoutes,
    BootstrapPortal,
    BootstrapAuthenticatedUser,
    HandleErrors,
    HandleLogout,
    MagicLink,
    HandleInactivity,
  ],

  /**
   * Data
   */
  data () {
    return {
      isError: false,
      error: null,
    }
  },

  /* COMPUTED */
  computed: {
    ...mapState('core/preferences', [
      'preferences'
    ]),

    ...mapGetters('core/user', [
      'hasUser',
      'user'
    ]),

    isModalCookies () {
      return this.user &&
        !isEmpty(this.preferences) &&
        !this.isProcessing &&
        !this.preferences.COOKIE_USAGE_ACCEPTED.value &&
        !this.isError;
    },
  },

  watch: {
    'hasUser': {
      handler (newVal) {
        if (newVal) {
          this.initializePersonalizationSocket();

          if ('serviceWorker' in navigator) {
            if ('PushManager' in window) {
              askNotificationPermission(this);
            }
            addTabsMessageListener(this);
          }

          this.initInactivityLogoutEventsListeners();
        }
      }
    }
  },

  /**
   * Before create hook
   */
  created () {
    this.register()
    this.boot()
  },

  /**
   * Component methods
   */
  methods: {
    /**
     * Register some handlers
     */
    async register () {
      await serviceWorkerRegistration();
    },

    /**
     * Bootstrap app
     */
    boot () {
      console.time('PORTAL LOADED');
      this.bootstrapAuthenticatedUser()
        .catch((e) => console.log(e))

      this.enableProcessing()

      this.loginViaMagicLink()
        .then(this.bootstrapPortal)
        .then(this.bootstrapRoutes)
        .then(this.bootstrapDelayedRoutes)
        .then(this.bootstrapVuex)
        .then(this.onAppBootstrap)
        .catch(this.onAppFailBootstrap)
        .finally(() => {
          console.timeEnd('PORTAL LOADED');
          this.disableProcessing();
        })
    },

    /**
     * Fire when app is bootstrapped
     */
    onAppBootstrap () {
      // This will update the current route after
      // additional routes were registered
      const { history } = this.$router
      history.updateRoute(INITIAL_ROUTE)

      const { route } = this.$router.resolve(
        history.getCurrentLocation()
      )

      history.transitionTo(route);

      if (this.user) {
        Sentry.setUser({id: this.user.id});
      }
    },

    /**
     * Fire when app failed bootstrap
     */
    onAppFailBootstrap (e) {
      console.error('onAppFailBootstrap', e)

      this.error = e;
      this.isError = true;
    },

    async initializePersonalizationSocket () {
      const accessToken = this.getAccessToken()?.access_token ?? '';

      if (!accessToken) return;

      const socket = await new WebSocket(process.env.VUE_APP_PERSONALIZATION_SOCKET_API);

      const subscribePayload = {
        action: PERSONALIZATION_SOCKET_ACTIONS.SUBSCRIBE,
        token: accessToken,
      };

      socket.onopen = () => {
        socketSend(socket, JSON.stringify(subscribePayload));
      }

      socket.onmessage = ({ data }) => {
        const res = JSON.parse(data);

        // If a manager deleted the portal - logout the user of the portal
        if (res.profile.user_id === this.user.id) {
          this.$logout();
        }
      }

      socket.onclose = () => {
        this.initializePersonalizationSocket();
      }
    }
  }
}
</script>

<style></style>
