export default {
    // Sentry error logger configs
    sentry: {
        dsn: process.env.VUE_APP_SENTRY_DSN,
    },

    // Google service configs
    google: {
        maps: {
            apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        },
        streetViewImageMeta: {
            api: process.env.VUE_APP_GOOGLE_MAPS_STREETVIEW_IMAGE_META,
            apiKey: process.env.VUE_APP_GOOGLE_MAPS_STREETVIEW_IMAGE_META_KEY,
        },
    },

    // Amplitude configs
    amplitude: {
        apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY,
        legacyApiKey: process.env.VUE_APP_LEGACY_AMPLITUDE_API_KEY,
    },
};
