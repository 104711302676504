import { createAsyncAction, createAsyncMutation, createAsyncState, createType } from '../../../../../core/utils/store';

/**
 * Types.
 */
const types = {
    SET_STATUSES: createType('SET_STATUSES'),
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
};

/**
 * Actions.
 */
const actions = {

};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {

};

/**
 * Default modules
 */
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};