<script>
    import Model from 'Core/mixins/Model';

    export default {
        /**
         * Component name
         */
        name: "BaseSwitcher",

        /**
         * Mixins
         */
        mixins: [
            Model,
        ],

        /**
         * Render function
         *
         * @param createElement
         * @param context
         * @return {null}
         */
        render(createElement, context) {
            return null;
        },
    }
</script>
