import { createAsyncAction, createAsyncMutation, createAsyncState, createType } from 'Core/utils/store';
import { styleGuideGateway } from '../../api/StyleGuidesGateway';
import StylesSchema from '../../services/style-guides/StylesSchema';

/**
 * Compose variables of style guides
 *
 * @param data
 * @return {{}}
 */
const composeVariables = (data) => {
    const styleGuide = {};
  
    data.forEach((setting) => {
        Object.assign(styleGuide, setting.values[0] || {});
    });

    return styleGuide;
}

/**
 * Types.
 */
const types = {
    LOAD_STYLE_GUIDES: createType('LOAD_STYLE_GUIDES'),
    SET_TEMPLATE_STYLE_GUIDE: 'SET_TEMPLATE_STYLE_GUIDE',
    SET_CLIENT_STYLE_GUIDE_SETTINGS: 'SET_CLIENT_STYLE_GUIDE_SETTINGS',
    SET_TEMPLATE_STYLE_GUIDE_SETTINGS: 'SET_TEMPLATE_STYLE_GUIDE_SETTINGS',
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
    styleGuides: [],
    templateStyleGuide: null,
    clientStyleGuideSettings: null,
    templateStyleGuideSettings: null,

    // Async states
    ...createAsyncState([
        types.LOAD_STYLE_GUIDES,
    ]),
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
    /**
     * Getter for pages
     *
     * @param state
     * @return {*}
     */
    styleGuides (state) {
        return state.styleGuides;
    },

    /**
     * Primary style guide
     *
     * @param state
     * @return {*}
     */
    primaryStyleGuide (state) {
        return state.styleGuides.find(guide => guide.is_primary);
    },

    /**
     * A fallback style guide used when primary styleguide is not set
     *
     * @param state
     * @return {*}
     */
    fallbackStyleGuide (state) {
        return state.styleGuides.find(guide => guide.is_default);
    },

    /**
     * Style guide of the client (on client level)
     *
     * @param state
     * @param getters
     * @return {any}
     */
    clientStyleGuide (state, getters) {
        return getters.primaryStyleGuide || getters.fallbackStyleGuide;
    },

    /**
     * Style guide of the template
     *
     * @param state
     * @return {null}
     */
    templateStyleGuide (state) {
        return state.templateStyleGuide;
    },

    /**
     * Client style guide schema
     *
     * @param state
     * @return {StylesSchema}
     */
    clientStyleGuideSettingsSchema (state) {
        return new StylesSchema(state.clientStyleGuideSettings);
    },

    /**
     * Template style guide schema
     *
     * @param state
     * @return {StylesSchema}
     */
    templateStyleGuideSettingsSchema (state) {
        return new StylesSchema(state.templateStyleGuideSettings);
    },
};

/**
 * Actions.
 */
const actions = {
    /**
     * Load styles
     */
    loadStyleGuides: createAsyncAction(types.LOAD_STYLE_GUIDES, () => {
        return styleGuideGateway.getStyleGuides();
    }),

    /**
     * Set template style guide
     *
     * @param dispatch
     * @param styleGuide
     */
    setTemplateStyleGuide ({ commit }, styleGuide) {
        commit(types.SET_TEMPLATE_STYLE_GUIDE, styleGuide);
    },
  
    setClientStyleGuidesSettings ({ commit }, settings) {
        commit(types.SET_CLIENT_STYLE_GUIDE_SETTINGS, settings);
    },
  
    setTemplateStyleGuidesSettings ({ commit }, settings) {
        commit(types.SET_TEMPLATE_STYLE_GUIDE_SETTINGS, settings);
    },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
    /**
     * Load template public pages mutation
     */
    ...createAsyncMutation(types.LOAD_STYLE_GUIDES, {
        success (state, { data }) {
            state.styleGuides = data.data;
        }
    }),

    /**
     * Set template styleguide
     *
     * @param state
     * @param styleGuide
     */
    [types.SET_TEMPLATE_STYLE_GUIDE] (state, styleGuide) {
        state.templateStyleGuide = styleGuide;
    },

    /**
     * Load settings of the client style guide level
     */
    [types.SET_CLIENT_STYLE_GUIDE_SETTINGS] (state, settings) {
        state.clientStyleGuideSettings = composeVariables(settings);
    },

    /**
     * Load settings of the template style guides
     */
    [types.SET_TEMPLATE_STYLE_GUIDE_SETTINGS] (state, settings) {
        state.templateStyleGuideSettings = composeVariables(settings);
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};