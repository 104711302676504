<template>
  <component
    :is="tag"
    :class="classes"
    :style="styles"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
    import WithTemplate from '../../mixins/WithTemplate';
    import WithStyleguideSchema from 'Portal/mixins/WithStyleguideSchema';

    export default {
        /**
         * Component name
         */
        name: "BaseText",

        /**
         * Component mixins
         */
        mixins: [
            WithTemplate,
            WithStyleguideSchema,
        ],

        /**
         * Component props
         */
        props: {
            /**
             * The element tag
             */
            tag: {
                type: String,
                default () {
                    return 'div';
                },
            },
        },

        /**
         * Computed properties
         */
        computed: {
            /**
             * Reactive styles
             *
             * @return {{}}
             */
            styles () {
                return {};
            },

            /**
             * Reactive classes
             *
             * @return {{}}
             */
            classes () {
                return {};
            },

            /**
             * Override in ur inherit components
             */
            definition () {
                return null;
            },

            /**
             * Css variables
             *
             * @return {*[]}
             */
            cssVariables () {
                if (! this.definition) {
                    return [];
                }

                return [
                    this.definition.getBackgroundColor(),
                    this.definition.getTextColor(),
                    this.definition.getFontFamily(),
                    this.definition.getFontSize(),
                    this.definition.getFontStyle(),
                ];
            },
        },
    }
</script>

<style scoped>

</style>
