<template>
  <div
    class="router-view-container"
    :class="cssClasses"
    v-bind="$attrs"
    :style="cssVariables"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import WithStyleguideSchema from 'Portal/mixins/WithStyleguideSchema';

const primaryCssClasses = "background-primary h-full w-full flex items-center justify-center bg-none page-content-background__with-primary";

export default {
  name: "RouterViewContainer",

  mixins: [
    WithStyleguideSchema,
  ],

  data() {
    return {
      background: "primaryBackground",
      cssVariables: null,
      cssClasses: primaryCssClasses,
    };
  },

  computed: {
    definition() {
      if (this.background === 'primaryBackground') {
        return this.styleGuideSchema.get('primary-background');
      }

      return {
        secondary: this.styleGuideSchema.get('secondary-background')?.setPrefix?.('secondary'),
        page: this.styleGuideSchema.get('page-background'),
      };
    },
  },

  watch: {
    '$route': function ({ meta: { background } }) {
      if (this.background === background) {
        return;
      }

      this.background = background;
      this.cssVariables = this.composeCssVariables(background);
      this.cssClasses = this.composeCssClass(background);
    }
  },

  created() {
    this.background = this.$router.currentRoute.meta.background;
    this.cssVariables = this.composeCssVariables();
    this.cssClasses = this.composeCssClass();
  },

  methods: {
    composeCssVariables(background = this.background || 'secondaryBackground') {
      if (!this.definition || !background) {
        return {};
      }

      return {
        primaryBackground: () => {
          const imageUrl = this.definition.getUrl?.();

          return [
            this.definition.getBackgroundColor?.(),
            this.definition.getShadowColor?.(),
            this.definition.getBackgroundSize?.('100%'),
            this.definition.getBackgroundPosition?.(),
            this.definition.getBackgroundAttachment?.(this.$screen.isMobile || this.$screen.isTablet),
            imageUrl ? { backgroundImage: `url(${imageUrl}` } : {},
          ]
        },
        secondaryBackground: () => {
          const imageUrl = this.definition.secondary?.getUrl?.();

          return [
            this.definition.page?.getBackgroundColor?.(),
            this.definition.secondary?.getBackgroundSize?.('100%'),
            this.definition.secondary?.getBackgroundPosition?.(),
            this.definition.secondary?.getBackgroundAttachment?.(this.$screen.isMobile || this.$screen.isTablet),
            imageUrl ? { backgroundImage: `url(${imageUrl})` } : {},
          ]
        },
      }[ background ]();
    },

    composeCssClass(background = this.background) {
      return background === "primaryBackground"
          ? primaryCssClasses
          : "page-content-background page-content-background__with-secondary"
    }
  }
}
</script>

<style type="text/scss" lang="scss">
</style>
