import { createAsyncAction, createAsyncMutation, createAsyncState, createType } from 'Core/utils/store';
import { personAddressesGateway } from 'Portal/api/PersonAddressesGateway';

/**
 * Types.
 */
const types = {
    LOAD_PERSON_ADDRESSES: createType('LOAD_PERSON_ADDRESSES'),
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
    addresses: [],

    // Async states
    ...createAsyncState([
        types.LOAD_PERSON_ADDRESSES,
    ])
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
    /**
     * Getter for template
     *
     * @param state
     * @return {*}
     */
    addresses (state) {
        return state.addresses;
    },

    /**
     * Current address
     *
     * @param state
     * @return {*}
     */
    currentAddress (state) {
        const currentAddress = state.addresses.find(address => address.is_default);

        if (currentAddress) {
            return currentAddress;
        }

        return state.addresses[state.addresses.length - 1];
    },
};

/**
 * Actions.
 */
const actions = {
    /**
     * Load template by hash
     */
    loadPersonAddresses: createAsyncAction(types.LOAD_PERSON_ADDRESSES, (store) => {
        return personAddressesGateway.getAddresses();
    }),
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
    /**
     * Load template by hash mutation
     */
    ...createAsyncMutation(types.LOAD_PERSON_ADDRESSES, {
        success (state, { data: { data }}) {
            state.addresses = data;
        }
    })
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};