import BaseGateway from 'Core/api/BaseGateway';
import { authGateway } from 'Core/api/AuthGateway';
import { mapActions } from 'vuex';
import BootstrapVuex from 'Portal/mixins/boot/BootstrapVuex';
import BootstrapRoutes from 'Portal/mixins/boot/BootstrapRoutes';
import HandleErrors from 'Core/mixins/HandleErrors';
import { logger } from 'Core/services/analytics/EventLogger';
import InteractsWithAccessToken from "Core/mixins/InteractsWithAccessToken";
import BootstrapPortal from "Portal/mixins/boot/BootstrapPortal";
import {HttpStatus} from "Core/utils/http";

export default {
    /**
     * Mixins
     */
    mixins: [
        HandleErrors,
        BootstrapVuex,
        BootstrapRoutes,
        BootstrapPortal,
        InteractsWithAccessToken,
    ],

    /**
     * Methods
     */
    methods: {
        /**
         * Vuex actions
         */
        ...mapActions('core/user', [
            'setAuthenticatedUser',
            'clearAuthenticatedUser',
        ]),
        ...mapActions('portal/person/address', [
            'loadPersonAddresses',
        ]),

        /**
         * Login user
         *
         * @param username
         * @param password
         */
        $login (username, password) {
            this.enableProcessing();

            const promise = authGateway.login(
                username, password, [
                    'personProfile.bankAccount',
                    'personProfile.availabilities'
                ],  this.credentials.params
            );

            return this.$resolve(promise, { runtime: true })
                .then(this.$handleLoginResponse)
                .then(this.bootstrapPortal)
                .then(() => {
                  return Promise.all([
                    this.loadPersonAddresses(),
                    this.bootstrapVuex(),
                    this.bootstrapRoutes(),
                  ]);
                })
                .catch((e) => {
                    console.log('Failed to login: ', e);

                    this.clearAuthenticatedUser();
                    this.$ls.remove('token');
                    this.$ls.remove('user');
  
                    const message = e.response.status === HttpStatus.UNAUTHORIZED ?
                      e.response.data.error :
                      this.$trans('portal.messages.error_login');
    
                    this.$notify.error(message);

                    throw e;
                })
                .finally(this.disableProcessing);
        },

        /**
         * Handle login response
         *
         * @param response
         */
        $handleLoginResponse ({ data }) {
            const user  = data.data;

            const tokenData = data.meta.custom;
  
            this.storeAccessToken(tokenData);
            this.$ls.set('user', user);

            this.setAuthenticatedUser(user);
            BaseGateway.addDefaultHeader(
                'Authorization',
                `${tokenData.token_type} ${tokenData.access_token}`
            );

            // In the portal the user id has to be person id
            logger.setUserIdentifier(user.personProfile.id);

            return true;
        },
    }
}
