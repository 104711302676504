import { authGateway } from 'Core/api/AuthGateway';

/**
 * Types
 */
const types = {
    SET_AUTHENTICATED_USER: 'SET_AUTHENTICATED_USER',
    CLEAR_AUTHENTICATED_USER: 'CLEAR_AUTHENTICATED_USER',
};

/**
 * Vuex state
 */
const state = {
    user: null,
};

/**
 * Vuex getters
 */
const getters = {
    /**
     * User getter
     *
     * @param state
     * @return {any}
     */
    user (state) {
        return state.user;
    },

    /**
     * Check that user in the system
     *
     * @param state
     * @return {boolean}
     */
    hasUser (state) {
        return !! state.user;
    },
};

/**
 * Vuex actions
 */
const actions = {
    /**
     * Set authenticated user in the system
     *
     * @param commit
     * @param user
     */
    setAuthenticatedUser ({ commit }, user) {
        commit(types.SET_AUTHENTICATED_USER, user);
    },

    /**
     * Clear authenticated user
     *
     * @param commit
     */
    clearAuthenticatedUser ({ commit }) {
        commit(types.CLEAR_AUTHENTICATED_USER);
    },

    /**
     * Logout user from system
     *
     * @param commit
     * @return {Promise<*>}
     */
    logout ({ commit }) {
      commit(types.CLEAR_AUTHENTICATED_USER);
      
      return Promise.resolve();
    },
};

/**
 * Mutations.
 */
const mutations = {
    /**
     * Set authenticated user
     *
     * @param state
     * @param user
     */
    [types.SET_AUTHENTICATED_USER] (state, user) {
        state.user = user;
    },

    /**
     * Clear authenticated user and set it to null
     *
     * @param state
     */
    [types.CLEAR_AUTHENTICATED_USER] (state) {
        state.user = null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};