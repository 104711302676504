import Languages from "Portal/store/modules/portal/languages";
import {portalGateway} from "Portal/api/PortalGateway";

const types = {
  SET_PORTAL: 'SET_PORTAL',
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
  portal: {}
};

/**
 * Getters
 *
 * @type {{}}
 */
const getters = {};

/**
 * Actions
 *
 * @type {{}}
 */
const actions = {
  loadBulkPortal ({ commit }) {
    return portalGateway.index().then((response) => {
      const bulk = response.data.data;

      commit(types.SET_PORTAL, bulk.portal);
      
      return bulk;
    });
  },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
  [types.SET_PORTAL] (state, portal) {
    state.portal = portal;
  },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
    modules: {
        settings: Languages
    },
};