import PasswordValidator from 'password-validator';

// You may change here validation schema for the password rule
export const passwordRules = {
    min: 8
};

export const passwordValidationSchema = (new PasswordValidator())
    .min(passwordRules.min)
    .uppercase()
    .lowercase()
    .digits();