import requestTypes from './types';
import page from './page';
import requestStatuses from './statuses';
import { createAsyncMutation, createAsyncAction, createAsyncState, createType } from 'Core/utils/store';
import { requestGateway } from 'Core/api/RequestGateway';
import sortBy from 'lodash/sortBy';
import uniq from "lodash/uniq";
import {managerGateway} from "Portal/api/ManagerGateway";

/**
 * Types.
 */
const types = {
    LOAD_REQUESTS: createType('LOAD_REQUESTS'),
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
    /**
     * Person requests
     */
    requests: [],

    /**
     * Async state
     */
    ...createAsyncState([
        types.LOAD_REQUESTS,
    ]),
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
    /**
     * Requests getter
     *
     * @param state
     * @return {[]}
     */
    requests (state) {
        return state.requests;
    },

    /**
     * Latest request with phase
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @return {null}
     */
    latestRequestWithPhase (state, getters, rootState, rootGetters) {
        const hasPhases = (request) => request.phases.length > 0 && !! request.currentPhase;

        const sortedRequest = sortBy(getters.requests, (request) => new Date(request.created_at))
            .filter(request => request.type && hasPhases(request));

        return sortedRequest[sortedRequest.length - 1];
    }
};

/**
 * Actions.
 */
const actions = {
    /**
     * Load person requests
     */
    loadRequests: createAsyncAction(types.LOAD_REQUESTS, ({ rootGetters }) => {
        return requestGateway.templateRequests(rootGetters['portal/template/template'].id).then((response) => {
            // Batch fetch managers for requests
            const requests = response.data.data;
            const ids = uniq(requests.filter(request => request.manager_id).map(request => request.manager_id));
    
            return managerGateway.getMany(ids).then(({ data }) => {
                requests.forEach(request => {
                    const managerResponse = data[request.manager_id];
            
                    if (managerResponse) {
                        request.manager = managerResponse.body.data;
                    }
                });

                return response;
            });
        });
    }),
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
    ...createAsyncMutation(types.LOAD_REQUESTS, {
        /**
         * Handle person requests
         *
         * @param state
         * @param data
         */
        success (state, { data }) {
            state.requests = data.data;
        }
    })
};

/**
 * Default modules
 */
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
    modules: {
        page,
        types: requestTypes,
        statuses: requestStatuses,
    },
};