import query from './Query';
import Query from './instance';
import Vue from 'vue';

window.query = query;

Vue.mixin({
    /**
     * Available methods.
     */
    methods: {
        /**
         * Check if the URL contains the given paramater key.
         *
         * @param key
         * @returns {boolean}
         */
        $hasParam (key) {
            return Query.has(key);
        },

        /**
         * Check if the URL misses the given paramater key.
         *
         * @param key
         * @returns {boolean}
         */
        $missingParam (key) {
            return Query.missing(key);
        },

        /**
         * Get the value for the given paramater key.
         *
         * @param key
         * @param callback
         * @returns {string}
         */
        $param (key, callback = null) {
            return Query.get(key, callback);
        },

        /**
         * Get all paramaters from the URL.
         *
         * @returns {object}
         */
        $params () {
            return Query.getAll();
        },
    }
});

