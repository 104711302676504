/**
 * Types.
 */
const types = {
    SET_STATE: 'SET_STATE',
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
    request: null,
    selectedPhase: null,
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {

    /**
     * Request
     *
     * @param state
     * @return {null}
     */
    request (state) {
        return state.request;
    },

    /**
     * Selected phase
     *
     * @param state
     * @return {*}
     */
    selectedPhase (state) {
        return state.selectedPhase;
    },
};

/**
 * Actions.
 */
const actions = {
    /**
     * Set phases sidebar state
     *
     * @param commit
     * @param payload
     */
    setPhasesSidebarState ({ commit }, payload) {
        commit(types.SET_STATE, payload);
    },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
    /**
     * Set state
     *
     * @param state
     * @param request
     * @param selectedPhase
     */
    [types.SET_STATE] (state, { request, selectedPhase }) {
        state.request = request;
        state.selectedPhase = selectedPhase;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};