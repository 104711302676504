import BaseGateway from 'Core/api/BaseGateway';
import UserServiceRequest from 'Core/api/requests/UserServiceRequest';

export default class ManagerGateway extends BaseGateway {
    /**
     * Get manager by id
     *
     * @param id
     * @return {Promise<*>}
     */
    getManagerById (id) {
        return this.request().get(`employees/${id}`);
    }

   /**
    * Get many managers by their ids
    *
    * @param ids
    * @param includes
    * @return {Promise<*>}
    */
    getMany (ids = [], includes = []) {
        const batch = ids.map((id) => {
            return {
                method: 'GET',
                name: id,
                relative_url: 'v1/employees/' + id,
            };
        });
    
        return this.request()
            .withBody({ batch })
            .withIncludes(includes)
            .post('batch');
    }

    /**
     * Make new http request object
     *
     * @return {UserServiceRequest}
     */
    makeRequest () {
        return new UserServiceRequest();
    }
}

/**
 * Instance of the gateway
 *
 * @type {ManagerGateway}
 */
export const managerGateway = new ManagerGateway();
