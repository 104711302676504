/**
 * This mixin is used to add ability to use a component with v-model directive.
 */
export default {
    /**
     * Available properties.
     */
    props: {
        /**
         * Component value.
         */
        value: {
            required: true,
        },
    },

    /**
     * Available methods.
     */
    methods: {
        /**
         * Close the component.
         */
        onInput (value) {
            this.$emit('input', value);
        },
    },
};
