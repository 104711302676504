import BaseGateway from '../../../core/api/BaseGateway';
import PortalServiceRequest from "../../../core/api/requests/PortalServiceRequest";

export default class StyleGuidesGateway extends BaseGateway {
    /**
     * Get client style guides
     *
     * @return {Promise<*>}
     */
    getStyleGuides () {
        return this.request().get('client/style-guides');
    }

    /**
     * Get settings of the style guide
     *
     * @param id
     * @return {Promise<*>}
     */
    getStyleGuideSettings (id) {
        return this.request().get(`client/style-guides/${id}/settings`);
    }

    /**
     * Make new http request object
     *
     * @return {PortalServiceRequest}
     */
    makeRequest () {
        return new PortalServiceRequest();
    }
}

/**
 * Instance of the gateway
 *
 * @type {StyleGuidesGateway}
 */
export const styleGuideGateway = new StyleGuidesGateway();
