import BaseGateway from './BaseGateway';
import RequestServiceRequest from './requests/RequestServiceRequest';
import PortalServiceRequest from "Core/api/requests/PortalServiceRequest";

/**
 * Request gateway
 */
export default class RequestGateway extends BaseGateway {
    /**
     * Get all requests of authenticated user
     *
     * @return {Promise<*>}
     * @param page
     * @param limit
     */
    getAllRequests (page = 1, limit = 20) {
        return this.request()
            .withIncludes(['manager', 'status', 'type', 'address'])
            .withParams({ page, limit })
            .get('requests');
    }

    /**
     * Get requests for certain home page
     *
     * @deprecated
     *
     * @param page_id
     * @return {Promise<*>}
     */
    getAllRequestsForPage (page_id) {
        return this.request()
            .withIncludes(['manager', 'status', 'type', 'address'])
            .withParams({ page_id, limit: 100 })
            .get('requests');
    }

    /**
     * Get request by it's id
     *
     * @param id
     * @return {Promise<*>}
     */
    getRequestById (id) {
        return this.request()
            .withIncludes(['manager', 'status', 'type', 'address'])
            .get(`requests/${id}`);
    }

    /**
     * Update request status
     *
     * @param id
     * @param status_id
     * @return {Promise<*>}
     */
    updateRequestStatus (id, status_id) {
        return this.request().withIncludes(['status']).withBody({ status_id }).post(`requests/${id}/status`)
    }

    /**
     * Create new request
     *
     * @param data
     * @return {Promise<*>}
     */
    createRequest (data) {
        return this.request()
            .withBody(data)
            .post('requests');
    }
    
    /**
     * Get template requests
     *
     * @param templateId
     * @return {*}
     */
    templateRequests (templateId) {
        return this.usingRequest(new PortalServiceRequest())
            .withIncludes(['manager', 'status', 'type', 'address'])
            .get(`templates/${templateId}/requests`);
    }
    
    /**
     * Find template by id
     *
     * @param templateId
     * @param requestId
     * @return {*}
     */
    findTemplateRequest (templateId, requestId) {
        return this.usingRequest(new PortalServiceRequest())
            .withIncludes(['manager', 'status', 'type', 'address'])
            .get(`templates/${templateId}/requests/${requestId}`);
    }

    updateInstallationDays (installationDays, requestId) {
        return this.request()
            .withBody({
                installation_days_suggestion: installationDays
            })
            .put(`requests/${requestId}`);
    }

    /**
     * New user request object
     *
     * @return {RequestServiceRequest}
     */
    makeRequest() {
        return new RequestServiceRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {RequestGateway}
 */
export const requestGateway = new RequestGateway();
