import translations from 'Core/locale';
import config from 'Core/config';
import ar from "element-ui/src/locale/lang/ar";

/**
 * Returns true if element is scrolled to bottom.
 * You can pass "offset" argument to implement lazy loading.
 *
 * @param el
 * @param offset
 * @returns {boolean}
 */
export const isScrolledToBottom = (el, offset = 0) => {
    return el.clientHeight + el.scrollTop + offset >= el.scrollHeight;
};

/**
 * Returns true if element is scrolled to top.
 * You can pass "offset" argument to implement lazy loading.
 *
 * @param el
 * @returns {boolean}
 */
export const isScrolledToTop = (el) => {
    return el.scrollTop === 0;
};

/**
 * Return localization key supported by the application. Example:
 * -> en
 *
 * @return {string}
 */
export const getSupportedLocalization = (supportedLocales = []) => {
    supportedLocales = supportedLocales.length > 0 ? supportedLocales : Object.keys(translations);

    const preferredUserLanguages = window.navigator.languages.map(language => language.split('-')[0]);

    const locale = preferredUserLanguages.find(lang => supportedLocales.includes(lang));

    return locale || supportedLocales[0] || config.fallbackLocale;
}

/**
 * Is this mobile size screen
 *
 * @return {boolean}
 */
export const isMobile = () => {
    return window.document.body.clientWidth < 600;
}

/**
 * Is tablet resolution
 *
 * @return {boolean}
 */
export const isTablet = () => {
    const width = window.document.body.clientWidth;

    return (width > 600) && (width < 1024);
}

/**
 * Is tablet resolution
 *
 * @return {boolean}
 */
export const isLaptop = () => {
    return window.document.body.clientWidth > 1024;
}

export const open = (...args) => window.open(...args);

export const body = () => window.document.body;

export const head = () => window.document.head;

export const scrollTo = (...args) => body().scrollTo.apply(body(), args);

export const redirect = (path) => window.location.href = path;

/**
 * Unescape some html tags
 *
 * @param html
 * @return {string}
 */
export const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  
  return txt.value;
};

export const htmlBodyContent = html => {
  const content = new DOMParser().parseFromString(html, "text/html");
  const bodyElement = content.getElementsByTagName("body")[0];
  return bodyElement.innerHTML?.trim() || '';
}

export const isHTML = str => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}