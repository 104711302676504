import {mapGetters} from 'vuex';
import WithCurrentPage from 'Portal/mixins/WithCurrentPage';
import pagesConfig from 'Portal/config/pages';

const check = (currentPage) => pagesConfig.clientStyleGuideRelatedPages.includes(currentPage?.type?.slug);

export default {
  mixins: [
    WithCurrentPage,
  ],
  
  data() {
    return {
      isClientStyleguide: true,
    };
  },
  
  created() {
    this.isClientStyleguide = check(this.currentPage);
  },
  
  watch: {
    currentPage(newPage, oldPage) {
      if (!oldPage) {
        this.isClientStyleguide = check(newPage)
      }
    }
  },
  
  computed: {
    ...mapGetters('portal/styleGuides', [
      'clientStyleGuideSettingsSchema',
      'templateStyleGuideSettingsSchema',
    ]),
    
    styleGuideSchema() {
      return this.isClientStyleguide ?
        this.clientStyleGuideSettingsSchema :
        this.templateStyleGuideSettingsSchema;
    },

    styleGuidePrimaryColor() {
      const button = this.styleGuideSchema.get('primary-button.active');
      
      if (button) {
        return button.getColorDefinition('background');
      }

      return null;
    },

    styleGuideLayoutBorderRadius () {
      const button = this.styleGuideSchema.getPrimaryButtonDefinition().active;

      if (!button) return;

      return button.getBorderRadius();
    }
  },
}
