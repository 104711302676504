import {mapGetters} from 'vuex';

export default {
    /**
     * Computed properties
     */
    computed: {
        ...mapGetters('portal/pages', [
            'pages',
        ]),

        /**
         * Current page getter
         *
         * @return {*}
         */
        currentPage () {
            const ref = this.$route.meta.page || {};

            // In some cases the ref is not the same
            // object as pages item, so we need
            // to check the id too
            return this.pages.find(page => page.id === ref.id);
        }
    },
}