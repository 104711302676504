import Vue from 'vue';
import { setInteractionMode } from 'vee-validate';
import translator from 'Core/services/translations/instance';
import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
import * as rules from 'vee-validate/dist/rules';
import VeeValidateEnglish from 'vee-validate/dist/locale/en.json';
import VeeValidateNetherland from 'vee-validate/dist/locale/nl.json';
import VeeValidateEspana from 'vee-validate/dist/locale/es.json';
import '../../validation/rules';

localize(translator.locale);
const messages = ({
    en: VeeValidateEnglish.messages,
    nl: VeeValidateNetherland.messages,
    es: VeeValidateEspana.messages,
})[translator.locale];

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

setInteractionMode('passive');
