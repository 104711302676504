import {createAsyncAction, createAsyncMutation, createAsyncState, createType} from '../../../../core/utils/store';
import {pagesGateway} from '../../api/PagesGateway';
import PageType from 'Portal/models/PageType';

/**
 * Types.
 */
const types = {
  SET_PAGES: 'SET_PAGES',
  SET_HEADER_MENU_PAGES: 'SET_HEADER_MENU_PAGES',
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
  pages: [],
  
  // Ids of the pages that belongs to the header
  headerMenuPages: [],
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
  /**
   * Getter for pages
   *
   * @param state
   * @return {*}
   */
  pages(state) {
    return state.pages;
  },
  
  /**
   * Menu pages getter
   *
   * @param state
   * @param getters
   * @return {*}
   */
  menuPages(state, getters) {
    return getters.pages.filter(page => state.headerMenuPages.includes(page.id));
  },

  isPersonalCabinetPage (state, getters) {
    return !!getters.pages.find(p => p.type.slug === PageType.PERSONAL_CABINET)
  }
};

/**
 * Actions.
 */
const actions = {
  /**
   * Load header menu
   */
  setHeaderMenuPages({ commit }, pages) {
    commit(types.SET_HEADER_MENU_PAGES, pages);
  },
  
  setPages({ commit }, pages) {
    commit(types.SET_PAGES, pages);
  },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
  [types.SET_PAGES] (state, pages) {
    state.pages = pages;
  },
  
  [types.SET_HEADER_MENU_PAGES] (state, pages) {
    state.headerMenuPages = pages.map(page => page.id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};