import template from './modules/template';
import pages from './modules/pages';
import person from './modules/person'
import requests from './modules/request';
import offers from './modules/offer';
import phases from './modules/phases';
import styleGuides from './modules/style-guides';
import layout from './modules/layout';
import portal from './modules/portal';

/**
 * Types.
 */
const types = {};

/**
 * State
 *
 * @type {{}}
 */
const state = {};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {};

/**
 * Actions.
 */
const actions = {};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
    modules: {
        pages,
        phases,
        template,
        requests,
        styleGuides,
        person,
        layout,
        offers,
        portal
    },
};