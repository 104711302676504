import trans from './trans';
import translator from './instance';
import Vue from 'vue';

window.trans = trans;

Vue.mixin({
    /**
     * Computed properties.
     */
    computed: {
        /**
         * Current locale.
         */
        $locale () {
            return translator.locale;
        },
    },

    /**
     * Available methods.
     */
    methods: {
        /**
         * Translate the key.
         *
         * @param key
         * @param replace
         * @returns {string}
         */
        $trans (key, replace) {
            return translator.trans(key, replace);
        },
    }
});

