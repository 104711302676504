import find from "lodash/find";
import PageType from "Portal/models/PageType";
import {createAsyncAction, createAsyncMutation, createAsyncState, createType} from "Core/utils/store";
import {pagesGateway} from "Portal/api/PagesGateway";

/**
 * This module is managing state of page in the phase
 */

/**
 * Types.
 */
const types = {
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SAVE_HISTORY: 'SAVE_HISTORY',
  LOAD_PAGES: createType('LOAD_PAGES'),
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
  // Current page in the phase
  currentPage: null,
  
  pages: [],
  pageHistoryInThePhase: [],

  ...createAsyncState([
    types.LOAD_PAGES,
  ]),
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
  /**
   * @param currentPage
   * @return {*}
   */
  currentPage ({ currentPage }) {
    return currentPage;
  },

  /**
   * Getter for current page based on pages map
   *
   * @param currentPage
   * @param pagesMap
   * @return {string}
   */
  currentPageType ({ currentPage }, { pagesMap }) {
    return Object.keys(pagesMap).find((type) => pagesMap[type] === currentPage?.id);
  },

  /**
   * @param pages
   * @return {*}
   */
  pages ({ pages }) {
    return pages;
  },
  
  pageHistoryInThePhase ({ pageHistoryInThePhase }) {
    return pageHistoryInThePhase;
  },

  /**
   * Generates key => value map
   * where key is page type and value page id
   *
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @return {{}}
   */
  pagesMap (state, getters, rootState, rootGetters) {
    const map = {};

    state.pages.forEach(page => {
      if (
        rootGetters['portal/template/template'].compareOffersPage?.id === page.id &&
        ! map[PageType.COMPARE_OFFERS_DROPDOWN]
      ) {
        map[PageType.COMPARE_OFFERS_DROPDOWN] = page.id;
        return;
      }
  
      map[page.type.slug] = page.id;
    });

    return map;
  }
};

/**
 * Actions.
 */
const actions = {
  /**
   * Load pages of the phase
   */
  loadPages: createAsyncAction(types.LOAD_PAGES, (state, { phase, params }) => {
    return pagesGateway.getPhasePages(phase.id, params, ['category', 'htmlBlocks', 'content'])
  }),

  /**
   * @param commit
   * @param get
   * @param type
   */
  changePageByType({ commit, getters, state }, type) {
    const id = getters.pagesMap[type];
  
    if (! id) {
      throw new Error('Page not found for given type');
    }
  
    const page = find(state.pages, ['id', id])
  
    commit(types.SET_CURRENT_PAGE, page);
    commit(types.SAVE_HISTORY, page);
  },
  
  /**
   * @param commit
   * @param getters
   */
  changePageToPrevious({ commit, getters }) {
    const currentPageIndexInHistory = getters.pageHistoryInThePhase.indexOf(getters.currentPage);
  
    if (currentPageIndexInHistory >= 1) {
      const newPage = getters.pageHistoryInThePhase[currentPageIndexInHistory - 1];

      commit(types.SET_CURRENT_PAGE, newPage);
    }
  },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
  /**
   * Handle pages load
   */
  ...createAsyncMutation(types.LOAD_PAGES, {
    success (state, response) {
      state.pages = response.data.data;
      state.currentPage = state.pages[0];
      state.pageHistoryInThePhase = [];

      if (state.currentPage) {
        state.pageHistoryInThePhase.push(state.currentPage);
      }
    },
  }),

  /**
   * Set current page and also add it to history
   *
   * @param state
   * @param page
   */
  [types.SET_CURRENT_PAGE] (state, page) {
    state.currentPage = page;
  },
  
  /**
   * Add page to history
   *
   * @param state
   * @param page
   */
  [types.SAVE_HISTORY] (state, page) {
    state.pageHistoryInThePhase.push(page);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};