import { personalizationGateway } from 'Core/api/PersonalizationGateway';
import { createType, createAsyncAction, createAsyncMutation, createAsyncState } from 'Core/utils/store';
import Preferences from 'Core/models/Preferences';
import keyBy from 'lodash/keyBy';

/**
 * Read boolean preference value
 *
 * @param pref
 * @return {boolean}
 */
const readBooleanPreference = (pref) => pref ? !! pref.value : false;

/**
 * Types
 */
const types = {
    LOAD_PREFERENCES: createType('LOAD_PREFERENCES'),
    SAVE_PREFERENCES: createType('SAVE_PREFERENCES'),
    TOGGLE_OBLIGATED_COOKIES: 'TOGGLE_OBLIGATED_COOKIES',
    TOGGLE_FUNCTIONAL_COOKIES: 'TOGGLE_FUNCTIONAL_COOKIES',
    TOGGLE_ANALYTICAL: 'TOGGLE_ANALYTICAL',
    TOGGLE_SMS_NOTIFICATION: 'TOGGLE_SMS_NOTIFICATION',
    TOGGLE_EMAIL_NOTIFICATION: 'TOGGLE_EMAIL_NOTIFICATION',
    TOGGLE_ACCEPT_COOKIES: 'TOGGLE_ACCEPT_COOKIES',
    TOGGLE_DISABLE_CHECKBOX: 'TOGGLE_DISABLE_CHECKBOX'
};

/**
 * Vuex state
 */
const state = {
    preferences: [],

    cookies: {
        obligated: false,
        functional: false,
        analytical: false,
    },

    notifications: {
        sms: false,
        email: false,
    },

    cookiesAccepted: false,

    isCheckboxDisabled: false,

    ...createAsyncState([
        types.LOAD_PREFERENCES,
        types.SAVE_PREFERENCES,
    ]),
};

/**
 * Vuex getters
 */
const getters = {
    /**
     * Get boolean preference
     *
     * @param state
     * @return {function(*): boolean}
     */
    getBooleanPreference (state) {
        return (type) => {
            return readBooleanPreference(state.preferences[type]);
        }
    },

    /**
     * Sms notification getter
     *
     * @param state
     * @param getters
     * @return {boolean}
     */
    smsNotifications (state, getters) {
        return getters.getBooleanPreference(Preferences.SMS_NOTIFICATION)
    },

    /**
     * Email notification getter
     *
     * @param state
     * @param getters
     * @return {boolean}
     */
    emailNotifications (state, getters) {
        return getters.getBooleanPreference(Preferences.EMAIL_NOTIFICATION)
    },

    /**
     * Obligated cookie usage
     *
     * @param state
     * @param getters
     * @return {function(*): boolean}
     */
    cookieUsageObligated (state, getters) {
        return getters.getBooleanPreference(Preferences.COOKIE_USAGE_OBLIGATED);
    },

    /**
     * Functional cookie usage
     *
     * @param state
     * @param getters
     * @return {function(*): boolean}
     */
    cookieUsageFunctional (state, getters) {
        return getters.getBooleanPreference(Preferences.COOKIE_USAGE_FUNCTIONAL);
    },

    /**
     * Analytical cookie usage
     *
     * @param state
     * @param getters
     * @return {function(*): boolean}
     */
    cookieUsageAnalytical (state, getters) {
        return getters.getBooleanPreference(Preferences.COOKIE_USAGE_ANALYTICAL);
    },
};

/**
 * Vuex actions
 */
const actions = {
    /**
     * Load user preferences
     */
    loadPreferences: createAsyncAction(types.LOAD_PREFERENCES, () => {
        return personalizationGateway.getUserPreferences();
    }),

    /**
     * Save user preferences
     */
    savePreferences: createAsyncAction(types.SAVE_PREFERENCES, (state, payload) => {
        return personalizationGateway.saveUserPreferences(payload);
    }),

    toggleObligatedCookies ({ commit }, payload) {
        commit(types.TOGGLE_OBLIGATED_COOKIES, payload)
    },

    toggleFunctionCookies ({ commit }, payload) {
        commit(types.TOGGLE_FUNCTIONAL_COOKIES, payload);
    },

    toggleAnalyticalCookies ({ commit }, payload) {
        commit(types.TOGGLE_ANALYTICAL, payload)
    },

    toggleSmsNotifications ({ commit }, payload) {
        commit(types.TOGGLE_SMS_NOTIFICATION, payload);
    },

    toggleEmailNotifications ({ commit }, payload) {
        commit(types.TOGGLE_EMAIL_NOTIFICATION, payload);
    },

    toggleAcceptCookies ({ commit }, payload) {
        commit(types.TOGGLE_ACCEPT_COOKIES, payload)
    },

    toggleDisableCheckbox ({ commit }, payload) {
        commit(types.TOGGLE_DISABLE_CHECKBOX, payload)
    }
};

/**
 * Mutations.
 */
const mutations = {
    /**
     * Load preferences
     */
    ...createAsyncMutation(types.LOAD_PREFERENCES, {
        success (state, data) {
            state.preferences = keyBy(data.data, 'name');
        },
    }),

    /**
     * Save preferences
     */
    ...createAsyncMutation(types.SAVE_PREFERENCES, {
        success (state, data) {
            data.data.forEach(preference => {
                state.preferences[preference.name] = preference;
            });
        },
    }),

    [types.TOGGLE_OBLIGATED_COOKIES] (state, payload) {
        state.cookies.obligated = payload;
    },

    [types.TOGGLE_FUNCTIONAL_COOKIES] (state, payload) {
        state.cookies.functional = payload;
    },

    [types.TOGGLE_ANALYTICAL] (state, payload) {
        state.cookies.analytical = payload;
    },

    [types.TOGGLE_SMS_NOTIFICATION] (state, payload) {
        state.notifications.sms = payload;
    },

    [types.TOGGLE_EMAIL_NOTIFICATION] (state, payload) {
        state.notifications.email = payload;
    },

    [types.TOGGLE_ACCEPT_COOKIES] (state, payload) {
        state.cookiesAccepted = payload;
    },

    [types.TOGGLE_DISABLE_CHECKBOX] (state, payload) {
        state.isCheckboxDisabled = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
