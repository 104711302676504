import { mapActions, mapGetters, mapMutations, } from "vuex";
import InteractsWithFavicon from "Portal/mixins/InteractsWithFavicon";
import insertFonts from "Portal/services/fonts";
import BaseGateway from "Core/api/BaseGateway";
import PortalError from "Portal/util/PortalError";
import { logger } from 'Core/services/analytics/EventLogger';

export default {
  mixins: [
    InteractsWithFavicon,
  ],
  
  computed: {
    ...mapGetters('core/user', ['hasUser']),
  },

  /**
   * Methods
   */
  methods: {
    ...mapActions({
      loadBulkPortal: 'portal/portal/loadBulkPortal',
      setClientStyleGuidesSettings: 'portal/styleGuides/setClientStyleGuidesSettings',
      setTemplateStyleGuidesSettings: 'portal/styleGuides/setTemplateStyleGuidesSettings',
      setPages: 'portal/pages/setPages',
      setHeaderMenuPages: 'portal/pages/setHeaderMenuPages',
      setTemplate: "portal/template/setTemplate",
      setRequestTypes: "portal/requests/types/setRequestTypes",
      setPrimaryLanguage: 'portal/portal/settings/setPrimaryLanguage',
    }),

    ...mapMutations({
      setClient: 'core/client/SET_CLIENT',
    }),
    
    /**
     * Load all for portal
     *
     * @return {Promise<any>}
     */
    bootstrapPortal(isLogin = false) {
      return this.loadBulkPortal().then((bulk) => {
        const {
          portal,
          products,
          template,
          pages,
          headerMenu,
          fonts,
          primaryStyleGuideSettings,
          templateStyleGuideSettings,
          client,
        } = bulk;

        insertFonts(fonts);

        this.setClientStyleGuidesSettings(primaryStyleGuideSettings);
        this.setTemplateStyleGuidesSettings(templateStyleGuideSettings);
        this.setFaviconFromStyleguide();
        this.setPages(pages);
        this.setHeaderMenuPages(headerMenu);
        this.setTemplate(template);
        this.setRequestTypes(products);
        this.setPrimaryLanguage(portal);
        this.setClient(client);
        
        if (template) {
          BaseGateway.addDefaultHeader('Template-Id', template);
        }

        if (isLogin) {
          logger.log('login', {
            'client_name': client.name,
          })
        }
        // @TODO: If this.hasUser and no template then logout user
      });
    },
  },
}
