import BaseGateway from './BaseGateway';
import PersonalizationRequest from 'Core/api/requests/PersonalizationRequest';

/**
 * PersonalizationGateway gateway
 */
export default class PersonalizationGateway extends BaseGateway {
    /**
     * Get preferences of the user
     *
     * @return {Promise<*>}
     */
    getUserPreferences () {
        return this.request().get('preferences');
    }

    /**
     * Save user preferences
     *
     * @param preferences
     * @return {Promise<*>}
     */
    saveUserPreferences (preferences) {
        return this.request().withBody({ preferences }).put('preferences');
    }

    /**
     * New user request object
     *
     * @return {PersonalizationRequest}
     */
    makeRequest() {
        return new PersonalizationRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {PersonalizationGateway}
 */
export const personalizationGateway = new PersonalizationGateway();
