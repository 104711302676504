import get from 'lodash/get'

/**
 * Check if given variable is a function
 *
 * @param func
 * @return {boolean}
 */
export const isFunction = (func) => typeof func === 'function';

/**
 * Laravel taps
 *
 * @param object
 * @param func
 * @return {*}
 */
export const tap = (object, func) => {
    func(object);

    return object;
};

/**
 * The alias for noop
 */
export const silence = () => {}

/**
 * True if environment is development
 *
 * @return {boolean}
 */
export const isDevelopmentEnv = () => process.env.NODE_ENV === 'development';

const documentTemplateParams = (
  offerTemplate = null,
) => {
  if (!offerTemplate) return {};

  const getOfferTemplateProp = templateProp => get(offerTemplate, templateProp, 0)

  return {
    ['bracket[settings][document_template][no_restrictions]']: getOfferTemplateProp('no_restrictions'),
    ['bracket[settings][document_template][based_on_quote]']: getOfferTemplateProp('based_on_quote'),
    ['bracket[settings][document_template][show_hidden_products]']: getOfferTemplateProp('show_hidden_products'),
    ['bracket[settings][document_template][rounded_amounts]']: getOfferTemplateProp('rounded_amounts'),
  };
}

export const bracketsQueryParams = ({
    requestId = '',
    managerId = '',
    offerId = '',
    isSigned = false,
    signedOfferId = '',
    offerTemplate = null,
  }) => {
  if (!requestId) return {};

  return {
    ['bracket[enabled]']: 1,
    ['bracket[settings][request_id]']: requestId,
    ...(managerId ?
        {
          ['bracket[settings][user_id]']: managerId
        } : {}
      ),
    ...(offerId ?
      {
        ['bracket[settings][qlid]']: offerId,
        ...documentTemplateParams(offerTemplate),
      } : {}), // for legacy - offer id
    ...(isSigned && signedOfferId ?
      {
        ['bracket[settings][signed]']: 1,
        ['bracket[settings][qlid_to_sign]']: signedOfferId,
      } : {}), // for legacy - signed offer id
  }
}
