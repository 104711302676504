import HandleErrors 	from 'Core/mixins/HandleErrors';
import { mapActions } from 'vuex';
import { logger } 		from 'Core/services/analytics/EventLogger';

export default {
	mixins: [
		HandleErrors,
	],

	methods: {
		...mapActions('portal/layout/sidebar', [
			'toggleSidebar',
		]),

		...mapActions('core/user', [
			'logout',
			'clearAuthenticatedUser',
		]),

		...mapActions('portal/template', [
			'clearTemplate',
		]),

		$logout () {
			return this.$resolve(this.logout())
				.finally(() => {
					this.clearTemplate();
					this.$ls.remove('user');
					this.$ls.remove('token');
					this.clearAuthenticatedUser();

					logger.log('logout');

					this.$router.push({ name: 'portal.login', query: this.$route.query });
				});
		},
	}
}
