import converter from 'hex-rgb';

/**
 * The optional function accepts any argument and allows you to access
 * properties or call methods on that object. If the given object is null,
 * properties and methods will return null instead of causing an error.
 *
 * The optional function also accepts a Closure as its second argument.
 * The Closure will be invoked if the value provided as the first argument is not null.
 *
 * @param value
 * @param callback
 * @returns {{}|*}
 */
export const optional = (value, callback) => {
    if (! callback) {
        return value || {};
    }

    if (value) {
        return callback(value);
    }

    return {};
};

/**
 * Sort object keys.
 * e.g. { c: 1, a: 2, b: 3 } becomes { a: 2, b: 3, c: 1 }
 *
 * @param value
 */
export const sortKeys = (value) => {
    const result = {};

    Object.keys(value).sort().forEach(key => {
        result[key] = value[key];
    });

    return result;
};

/**
 * Return object keys array when it's value is true
 * Example:
 *
 * objectTrueKeys({active: false, disabled: true});
 * -> ['disabled']
 *
 * @param object
 * @return {string[]}
 */
export const objectTrueKeys = (object) => {
  const keys = Object.keys(object);

  return keys.filter(key => object[key]);
};

/**
 * Get the first property which is not falsy value
 *
 * @param object
 * @param properties
 * @return {*}
 */
export const getFirstProperty = (object, properties) => {
    const propertiesSet = Array.isArray(properties) ? properties : [properties];

    const values = propertiesSet.map(property => object[property]).filter(value => value);

    return values[0] || null;
};

/**
 * Is null check
 *
 * @param value
 * @return {boolean}
 */
export const isNull = (value) => value === null;

/**
 * Is undefined check
 *
 * @param value
 * @return {boolean}
 */
export const isUndefined = (value) => value === undefined;

/**
 * Is undefined or null check
 *
 * @param value
 * @return boolean
 */
export const isUndefinedOrNull = (value) => isUndefined(value) || isNull(value);

/**
 * Hex to rgb
 *
 * @param hex
 * @param defaultValue
 * @return {{red: number, green: number, blue: number}}
 */
export const hexToRgb = (hex, defaultValue = {red: 255, green: 255, blue: 255}) => {
    try {
        return converter(hex);
    } catch (e) {
        return defaultValue;
    }
}

/**
 * Get file extension
 *
 * @param file
 * @return {*}
 */
export const getFileExtension = (file) => {
    const fromFilename = (filename) => filename.split('.').pop();

    if (file instanceof File) {
        return fromFilename(file.name);
    }

    return fromFilename(file);
}
