import BaseGateway from '../../../core/api/BaseGateway';
import PortalServiceRequest from "../../../core/api/requests/PortalServiceRequest";

export default class PagesGateway extends BaseGateway {
  /**
   * Get pages
   *
   * @return {Promise<*>}
   */
  getPages() {
    return this.request()
    .withIncludes(['category'])
    .withParams({
      limit: 1000,
    })
    .get('client/pages');
  }
  
  /**
   * Get all pages of the template
   *
   * @return Promise<*>
   * @param hash
   */
  getTemplatePages(hash) {
    return this.request().get(`templates/${hash}/pages`);
  }
  
  /**
   * Get public pages
   *
   * @param hash
   * @return {Promise<*>}
   */
  getTemplatePublicPages(hash) {
    return this.request().get(`templates/${hash}/public-pages`);
  }
  
  /**
   * Get page content by it's id
   *
   * @param id
   * @param params
   * @return {Promise<*>}
   */
  getPageContent(id, params = {}) {
    return this.request().withParams(params).get(`pages/${id}/content`);
  }
  
  /**
   * Get pages of the phase
   *
   * @param phaseId
   * @param params
   * @param includes
   * @return {Promise<*>}
   */
  getPhasePages(phaseId, params, includes = []) {
    return this.request()
    .withParams(params)
    .withIncludes(includes)
    .get(`phases/${phaseId}/pages`);
  }
  
  /**
   * Get header menu pages
   *
   * @param templateId
   * @param includes
   * @return {Promise<*>}
   */
  getHeaderMenuPages(templateId, includes = []) {
    return this.request()
    .withIncludes(includes)
    .get(`templates/${templateId}/header-menu`);
  }
  
  /**
   * Get html blocks of the page
   *
   * @param pageId
   * @param params
   * @return {Promise<*>}
   */
  htmlBlocks(pageId, params) {
    return this.request()
    .withParams(params)
    .get(`pages/${pageId}/page-html-blocks`);
  }
  
  /**
   * New http request
   *
   * @return {PortalServiceRequest}
   */
  makeRequest() {
    return new PortalServiceRequest();
  }
}

/**
 * Pages gateway
 *
 * @type {PagesGateway}
 */
export const pagesGateway = new PagesGateway();
