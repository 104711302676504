const types = {
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
};

const state = {
    sidebar: null,
};

const getters = {
    activeSideBar (state) {
        return state.sidebar;
    },
};

const actions = {
    toggleSidebar ({ commit }, payload) {
        commit(types.TOGGLE_SIDEBAR, payload);
    },
};

const mutations = {
    [types.TOGGLE_SIDEBAR] (state, { name }) {
        state.sidebar = state.sidebar === name ? null :name;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
