/**
 * Types.
 */
const types = {
    CLEAR_TEMPLATE: 'CLEAR_TEMPLATE',
    SET_TEMPLATE: 'SET_TEMPLATE',
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
    template: null,
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
    /**
     * Getter for template
     *
     * @param state
     * @return {*}
     */
    template (state) {
        return state.template;
    },
};

/**
 * Actions.
 */
const actions = {
    /**
     * Load template
     */
    setTemplate ({ commit }, template) {
        commit(types.SET_TEMPLATE, template);
    },

    /**
     * Clear template
     *
     * @param commit
     */
    clearTemplate ({ commit }) {
        commit(types.CLEAR_TEMPLATE);
    },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
    [types.SET_TEMPLATE] (state, template) {
        state.template = template;
    },

    /**
     * Clear template data
     *
     * @param state
     */
    [types.CLEAR_TEMPLATE] (state) {
        state.template = null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};