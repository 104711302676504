import moment from "moment";

/**
 * Export useful constants to avoid magic numbers all over the system.
 * Carbon has these constants, but moment.js doesn't, that's why we need to do
 * the same manually.
 *
 * @type {number}
 */
export const MINUTES_PER_HOUR = 60;

/**
 * Monday is counted as a first (0) day of the week.
 *
 * @type {number}
 */
export const WEEKDAY_MONDAY = 0;

/**
 * Fetches minutes from time string
 * For example 01:30
 *
 * @param string
 *
 * @return {number}
 */
export const fetchMinutes = (string) => moment.duration(string).asMinutes();

/**
 * Format date to send to API.
 *
 * @param date
 */
export const mapDateTimeToApi = (date) => {
    const formatted = moment(date).toISOString(true);

    return formatted.substring(0, formatted.indexOf('+'));
};

/**
 * Returns true if the passed date is monday.
 *
 * @param date
 * @returns {boolean}
 */
export const isMonday = (date) => {
    return date.weekday() === WEEKDAY_MONDAY;
};

/**
 * Returns true if the passed date is first date of month (e.g. 1st of April).
 *
 * @param date
 * @returns {boolean}
 */
export const isFirstDateOfMonth = (date) => {
    return date.get('date') === 1;
};

/**
 * Converts seconds to hours
 *
 * @param seconds
 *
 * @return {number}
 */
export const secondsToHours = (seconds) => seconds / MINUTES_PER_HOUR / MINUTES_PER_HOUR;

/**
 * Parse the passed date and create a moment instance to avoid deprecation warnings.
 *
 * @param date
 * @returns {moment.Moment}
 */
export const parseDate = (date) => {
    return moment((new Date(date)).toISOString());
};

/**
 * Shift original date depending on how date is changed
 *
 * @param original
 * @param newDate
 * @param oldDate
 * @return {moment.Moment}
 */
export const shiftTime = (original, newDate, oldDate) => {
    const newStartAt = moment(newDate);
    const oldStartAt = moment(oldDate);

    const shiftInMinutes = newStartAt.diff(oldStartAt, 'minutes');

    return moment(original).add(shiftInMinutes, 'minutes');
};

/**
 * Get timezone as gmt offset
 *
 * @return {string}
 */
export const getTimezone = () => {
    const numberToString = (number) => number < 10 ? '0' + number : number;

    const offset = -(new Date().getTimezoneOffset());
  
    const hour = Math.abs(Math.floor(offset / MINUTES_PER_HOUR));
    const minutes = Math.abs(offset % MINUTES_PER_HOUR);
    const sign = offset >= 0 ? '+' : '-';
    
    return `GMT${sign}${numberToString(hour)}:${numberToString(minutes)}`;
};
