import BaseGateway from '../../../core/api/BaseGateway';
import RequestServiceRequest from 'Core/api/requests/RequestServiceRequest';
import PortalServiceRequest from "../../../core/api/requests/PortalServiceRequest";

export class RequestTypesGateway extends BaseGateway {
    /**
     * Get template types
     *
     * @param templateId
     * @param includes
     *
     * @return {Promise<*>}
     */
    getTemplateTypes (templateId, includes = []) {
        return this.usingRequest(new PortalServiceRequest())
            .withIncludes(includes)
            .get(`templates/${templateId}/products`)
    }

    /**
     * Get available types
     *
     * @param includes
     * @return {Promise<*>}
     */
    getAvailableTypes (includes = []) {
        return this.request()
            .withIncludes(includes)
            .get('types/available');
    }

    /**
     * New http request
     *
     * @return {RequestServiceRequest}
     */
    makeRequest () {
        return new RequestServiceRequest();
    }
}

/**
 * Gateway instance
 *
 * @type {RequestTypesGateway}
 */
export const requestTypesGateway = new RequestTypesGateway()