import Query from "Core/services/query/Query";
import {authorizerGateway} from "Core/api/AuthorizerGateway";
import moment from 'moment';
import HandleLogin from "Portal/mixins/HandleLogin";
import HandleLogout from "Portal/mixins/HandleLogout";
import BaseGateway from "Core/api/BaseGateway";
import {mapGetters} from "vuex";
import {isMagicLinkRequest} from "Core/utils/http";
import InteractsWithAccessToken from "Core/mixins/InteractsWithAccessToken";

export default {
  mixins: [
    HandleLogin,
    HandleLogout,
    InteractsWithAccessToken,
  ],
  
  computed: {
    /**
     * Vuex getters
     */
    ...mapGetters('core/user', ['user', 'hasUser']),
  },
  
  /**
   * Methods
   */
  methods: {
    /**
     * Login user via magic link if it is in url
     *
     * @return {Promise<void>}
     */
    loginViaMagicLink() {
      if (! isMagicLinkRequest()) {
        return Promise.resolve();
      }

      const token = (new Query()).get('token');
      
      const login = () => {
        return authorizerGateway.loginViaMagicLink(token).then(({data}) => {
          const tokenData = {
            access_token: data.idToken,
            refresh_token: data.refreshToken,
            token_type: data.tokenType,
            expires_in: data.expiresAt - moment().unix(),
          };
          
          this.storeAccessToken(tokenData);

          BaseGateway.addDefaultHeader(
            'Authorization',
            `${tokenData.token_type} ${tokenData.access_token}`
          );
          
          return this.refreshData().then(() => {
            const dataObject = {
              data: this.user,
              meta: {
                custom: tokenData,
              }
            };
            
            // Handle user data like it is response from api call
            return this.$handleLoginResponse({data: dataObject});
          });
        }).catch((e) => {
          console.error(e);
          
          return Promise.resolve();
        });
      }
      
      this.clearTemplate();
      this.$ls.remove('user');
      this.$ls.remove('token');
      this.clearAuthenticatedUser();
      
      return login();
    },
  },
}
