import { createAsyncAction, createAsyncMutation, createAsyncState, createType } from 'Core/utils/store';
import flatten from 'lodash/flatten';

/**
 * Types.
 */
const types = {
    SET_PHASES: createType('SET_PHASES'),
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
    /**
     * Phases getter
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @return {any}
     */
    phases (state, getters, rootState, rootGetters) {
        const products = rootGetters['portal/requests/types/types'];

        return flatten(
            products.map(x => x.phases)
        );
    },

    /**
     * Phase id by id
     *
     * @param state
     * @param getters
     * @return {function(*): *}
     */
    getPhaseById (state, getters) {
        return (phaseId) => {
            return getters.phases.find(phase => phase.id === phaseId);
        };
    },
  
    /**
     * Get phase by its order number
     * @param state
     * @param getters
     * @return {function(*): *}
     */
    getPhaseByOrder (state, getters) {
        return (order) => {
            return getters.phases.find(phase => phase.order === order);
        };
    },

    /**
     * Get phases of the request type
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @return {function(...[*]=)}
     */
    getRequestTypePhases (state, getters, rootState, rootGetters) {
        return (typeId) => {
            const product = rootGetters['portal/requests/types/types'].find(x => x.product_id === typeId);

            return product ? product.phases : []
        }
    }
};

/**
 * Actions.
 */
const actions = {

};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
};

/**
 * Default modules
 */
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};