<script>
    import WithStyleguideSchema from 'Portal/mixins/WithStyleguideSchema';

    export default {
        /**
         * Component name
         */
        name: "BaseButton",

        mixins: [
            WithStyleguideSchema,
        ],

        /**
         * Computed properties
         */
        computed: {
            /**
             * Override in ur inherit components
             */
            definition () {
                return null;
            },

            buttonStates () {
                return ['active', 'hover', 'disable'];
            },

            /**
             * Css variables
             *
             * @return {*[]}
             */
            cssVariables () {
                if (! this.definition) {
                    return [];
                }

                const getVarsForState = (state) => {
                    return [
                        this.definition[state].getTextColor(),
                        this.definition[state].getBackgroundColor(),
                        this.definition[state].getBorderColor(),
                        this.definition[state].getShadowColor(),
                        this.definition[state].getFontFamily(),
                        this.definition[state].getFontSize(),
                        this.definition[state].getFontStyle(),
                        this.definition[state].getBorderRadius(),
                    ]
                }

                return this.buttonStates.reduce((acc, currentState) => {
                    acc.push(...getVarsForState(currentState));
                    return acc;
                }, []);
            },
        },

        /**
         * Render function
         *
         * @return {null}
         */
        render () {
            return null;
        },
    }
</script>
