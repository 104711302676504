import portal from 'Portal/store';
import core from 'Core/store/main';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        portal,
        core,
    },
});
