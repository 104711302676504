import Vue from 'vue';
import { optional } from '../../utils/object';
import CustomPageRedirect from '../../../containers/portal/mixins/PageRedirect';

Vue.mixin({
  mixins: [
    CustomPageRedirect,
  ],
  
  /**
   * Mixin methods
   */
  methods: {
    /**
     * Optional helper.
     */
    $optional: optional,
    
    /**
     * Call a function with passed arguments on the ref element.
     *
     * @param component
     * @param ref
     * @param name
     * @param args
     * @returns {*}
     */
    $callOnRef (component, ref, name, args) {
      if (!component.$refs[ ref ]) {
        return;
      }
      
      return component.$refs[ ref ][ name ](args);
    },
    
    $setData (key, nested = true) {
      return ({ data }) => {
        return (this[ key ] = nested ? data.data : data);
      };
    },
    
    $getData (response) {
      return response.data.data;
    },
  }
});
