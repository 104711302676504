import { HttpStatus } from '../utils/http';
import noop from 'lodash/noop';

/**
 * Http error status handler
 */
export class HttpErrorHandler {
    /**
     * @param context
     * @param statusCode
     * @param body
     * @param headers
     */
    constructor (
        context,
        statusCode,
        body = {},
        headers = {},
    ) {
        this.body = body;
        this.handlers = {};
        this.context = context;
        this.headers = headers;
        this.statusCode = statusCode;

        this.initialize();
    }

    /**
     * Initialize the handlers
     */
    initialize () {
        this.handlers = {
            [HttpStatus.INTERNAL_SERVER_ERROR]: [
                this.internalServerErrorNotification,
            ],
            [HttpStatus.UNPROCESSABLE_ENTITY]: [
                this.populateErrorBags,
            ],
            [HttpStatus.FORBIDDEN]: [
                this.forbiddenNotification,
                this.populateErrorBags,
            ],
            [HttpStatus.BAD_REQUEST]: [
                this.populateErrorBags,
            ],
            [HttpStatus.TOO_MANY_REQUESTS]: [
                this.handleApiThrottle
            ],
            [HttpStatus.NOT_FOUND]: [
                this.populateErrorBags,
            ],
        };
    }

    /**
     * Return input params for error handler
     *
     * @return {{headers: {}, context: *, body: {}, statusCode: *}}
     */
    resolveHandlerParams () {
        return {
            body: this.body,
            context: this.context,
            headers: this.headers,
            statusCode: this.statusCode,
        };
    }

    /**
     * Invoke the handler function based on http status
     *
     * @param customHandlers
     */
    invoke (customHandlers = {}) {
        const handlers = Object.assign({}, this.handlers, customHandlers);

        // Make sure functions is an array
        let functions = Array.isArray(handlers[this.statusCode]) ? handlers[this.statusCode] : [handlers[this.statusCode]];
        functions = functions.map(func => func || noop);

        functions.forEach(func => func(this.resolveHandlerParams()));
    }

    //----------------------------HANDLER FUNCTIONS----------------------------------//

    /**
     * Handles server fatal errors
     *
     * @param context
     */
    internalServerErrorNotification ({ context }) {
        context.$notify.error('An unknown error has occurred. Please try again later.');
    }

    /**
     * Handles forbidden notifications
     *
     * @param context
     * @param body
     */
    forbiddenNotification ({ context, body }) {
        context.$notify.warning(body.message);
    }

    /**
     * Populates validation error bags
     *
     * @param e
     */
    populateErrorBags ({ context, body }) {
        const errors = body.errors ? body.errors : {
            common: [body.message || body.error],
        };

        const observer = context.$refs.observer;

        if (observer) {
            observer.setErrors(errors);
        }
    }

    /**
     * Handle when too many requests were done
     *
     * @param context
     */
    handleApiThrottle ({ context }) {
        context.$notify.warning('Too many attempts. Please try again later.');
    }
}
