/**
 * Auth middleware for router
 *
 * @param next
 * @param store
 * @param to
 *
 * @return {*}
 */
export default function auth ({ redirect, store, to }) {
    const isLogged = store.getters['core/user/hasUser'];
    
    if (! isLogged) {
        return redirect({
            name: 'portal.login',
            query: { redirect: to.fullPath }
        });
    }
};
