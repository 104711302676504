import Vue from 'vue';
import VueScreen from 'vue-screen';

Vue.use(VueScreen, {
  extend: 'tailwind',
  mobile: screen => ({
    vendor: screen.touch
      ? navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && /\biPhone\b/i.test(navigator.userAgent)
        ? 'iphone'
        : /\bAndroid\b/i.test(navigator.userAgent)
          ? 'android'
          : 'other-mobile-device'
      : '',
    orientation: screen.landscape && screen.touch ? 'landscape' : 'portrait',
  }),
  breakpoint(screen) {
    if (screen.xl) {
      return 'xl';
    }
    
    if (screen.lg) {
      return 'lg';
    }
    
    if (screen.md) {
      return 'md';
    }
    
    if (screen.sm) {
      return 'sm';
    }
    
    return 'xs';
  },
  tablet: 600,
  laptop: 1024,
  
  isMobile: screen => screen.touch && screen.width < 600 && !screen.laptop,
  isTablet: (screen) => screen.tablet && !screen.laptop,
  isLaptop: (screen) => !screen.tablet && screen.laptop,
});
