import instance from "Core/services/translations/instance";
import moment from 'moment';
import {getSupportedLocalization} from "Core/utils/dom";
import BaseGateway from "Core/api/BaseGateway";

const types = {
  SET_PRIMARY_LANGUAGE: 'SET_PRIMARY_LANGUAGE',
};

/**
 * Actions.
 */
const actions = {
  setPrimaryLanguage ({ commit }, data) {
    commit(types.SET_PRIMARY_LANGUAGE, data);
  },
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
  isChatEnabled: ({isChatEnabled}) => !!isChatEnabled,
}

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
  [types.SET_PRIMARY_LANGUAGE] (state, data) {
    const locale = data.default_locale ? getSupportedLocalization(data.available_languages) : data.locale;
  
    instance.setLocale(locale);
    moment.locale(locale);
    BaseGateway.addDefaultHeader('Accept-Language', locale);
  
    return Object.assign(state, {
      isChatEnabled: data.is_chat_enabled
    });
  },
};

/**
 * State
 *
 * @type {{}}
 */
const state = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
  modules: {},
};