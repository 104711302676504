<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
    viewBox="0 0 16 12"
  ><path
    d="M8 5.32L14.973.666H1.03L8 5.319zm8 6.013V1.497L8 6.843 0 1.505v9.828h16z"
    clip-rule="evenodd"
  /><mask
    id="a"
    width="16"
    height="12"
    x="0"
    y="0"
    maskUnits="userSpaceOnUse"
  ><path
    fill="#fff"
    fill-rule="evenodd"
    d="M8 5.32L14.973.666H1.03L8 5.319zm8 6.013V1.497L8 6.843 0 1.505v9.828h16z"
    clip-rule="evenodd"
  /></mask></svg>
</template>

<script>
    import Icon from './Icon';

    export default {
        /**
         * Component name.
         */
        name: 'LetterIcon',

        /**
         * Extending the component.
         */
        extends: Icon,
    }
</script>

<style scoped>

</style>
