import Request from './Request';
import config from '../../config/index';

export default class AuthorizerRequest extends Request {
	/**
	 * Constructor
	 */
	constructor() {
		super(config.api.authorizerService);
	}
}
