import money from './instance';
import Vue from 'vue';

window.money = money;

Vue.mixin({
    /**
     * Available methods.
     */
    methods: {
        /**
         * Format money.
         *
         * @param amount
         * @return {string}
         */
        $formatMoney (amount, withCurrencyIcon) {
            return money.format(amount, typeof withCurrencyIcon !== 'undefined' ? withCurrencyIcon : true);
        },
    }
});

