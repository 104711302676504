import auth from '../../../core/router/middleware/auth';
import analytics from 'Portal/router/middleware/analytics';
import PageType from 'Portal/models/PageType';

export default {
    functional: {
        login: {
            1: {
                name: 'portal.login',
                path: '/login',
                meta: {
                    background: 'primaryBackground',
                },
                components: {
                    default: () => import('../pages/auth/login/FirstLayoutLoginPage'),
                },
            },
        },
        'forgot-password': {
            1: {
                path: '/forgot-password',
                name: 'portal.forgot-password',
                meta: {
                    background: 'primaryBackground',
                },
                components: {
                    default: () => import('../pages/auth/forgot-password/FirstLayoutForgotPasswordPage')
                },
            },
        },
        'reset-password': {
            1: {
                path: '/reset-password',
                name: 'portal.reset-password',
                meta: {
                    background: 'primaryBackground',
                },
                components: {
                    default: () => import('../pages/auth/reset-password/FirstLayoutResetPasswordPage'),
                },
            },
        },
        home: {
            1: {
                path: '/home/:slug--:id',
                name: 'portal.home',
                components: {
                    header: () => import('../components/headers/AppHeader'),
                    footer: () => import('../components/footers/AppFooter'),
                    default: () => import('../pages/auth/home/FirstLayoutHomePage'),
                },
                meta: {
                    middleware: { attach: [ auth, analytics ] },
                },
            }
        },
        'personal-cabinet': {
            1: {
                path: '/account',
                name: 'portal.profile.info',
                components: {
                    header: () => import('Portal/components/headers/AppHeader'),
                    footer: () => import('Portal/components/footers/AppFooter'),
                    default: () => import('Portal/pages/profile/info/PersonalInfoPage')
                },
                meta: {
                    middleware: { attach: [ auth, analytics ] },
                },
            },
        },
        'personal-settings': {
            1: {
                path: '/settings',
                name: 'portal.profile.settings',
                components: {
                    header: () => import('Portal/components/headers/AppHeader'),
                    footer: () => import('Portal/components/footers/AppFooter'),
                    default: () => import('Portal/pages/profile/settings/PersonalSettingsPage')
                },
                meta: {
                    middleware: { attach: [ auth, analytics ] },
                },
            },
        }
    },
    custom: {
        1: {
            path: '/pages/:slug--:id',
            name: 'portal.custom-page',
            components: {
                header: () => import('../components/headers/AppHeader'),
                footer: () => import('../components/footers/AppFooter'),
                default: () => import('../pages/custom/PendingCustomPage'),
            },
            meta: {
                middleware: { attach: [ auth, analytics ] },
            },
        }
    },
    
    home: {
        1: {
            path: '/home',
            name: 'portal.home',
            components: {
                header: () => import('../components/headers/AppHeader'),
                footer: () => import('../components/footers/AppFooter'),
                default: () => import('../pages/auth/home/FirstLayoutHomePage'),
            },
            meta: {
                middleware: { attach: [ auth, analytics ] },
            },
        }
    },

    /**
     * The pages that is related to client styleguide
     */
    clientStyleGuideRelatedPages: [
        PageType.LOGIN,
        PageType.FORGOT_PASSWORD,
        PageType.RESET_PASSWORD,
    ],
}
