import Request from './Request';
import config from '../../config';

export default class PersonalizationRequest extends Request {
    /**
     * Constructor
     */
    constructor() {
        super(config.api.personalization);
    }
}
