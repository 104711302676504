<template>
  <span
    class="heading-3 block"
    :style="cssVariables"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </span>
</template>

<script>
    import BaseText from './BaseText';

    export default {
        /**
         * Component name
         */
        name: "Heading3",

        /**
         * Extend base component
         */
        extends: BaseText,

        /**
         * Computed properties
         */
        computed: {
            /**
             * Definition of styles for heading1
             *
             * @return {StyleDefinition|null}
             */
            definition () {
                return this.styleGuideSchema.get('h3');
            },
        },
    }
</script>

<style scoped>

</style>
