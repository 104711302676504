export default {
    /**
     * Methods
     */
    methods: {
        /**
         * Set page title
         *
         * @param title
         */
        setPageTitle (title) {
            window.document.title = title;
        },

        /**
         * Get page title
         *
         * @return {string}
         */
        getPageTitle () {
            return window.document.title;
        },
    }
}