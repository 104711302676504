import debounce 						from 'lodash/debounce';
import ServiceWorkerType		from 'Portal/models/ServiceWorkerType';
import get 									from 'lodash/get';
const EVENTS_FOR_LISTENING = [
	'mousemove',
	'click',
	'keypress',
	'touchend',
];
const VISIBILITY_CHANGE_EVENT = 'visibilitychange';
const INACTIVITY_LOGOUT_TIME 	= 6 * 1000 * 10 * 60 * 8; // Logout timer 8 hours if the user was inactive
const DEBOUNCE_DELAY 					= 300;
const DOCUMENT_STATE_VISIBLE 	= 'visible';

export default {
  data () {
		return {
			timer: null,
		}
	},

  methods: {
    /**
     * Event listener for visibilitychange event
     * If the tab is opened adds listeners for checking user acitivty
     * If not removes the listeners
     * And resets the timer
     */
    visibilityEventListener () {
			if (document.visibilityState === DOCUMENT_STATE_VISIBLE) {
				this.addEventsListeners();
				
				if ('serviceWorker' in navigator) {
					setTimeout(() => {
						navigator.serviceWorker.ready.then(() => {
							navigator?.serviceWorker?.controller?.postMessage({ type: ServiceWorkerType.TAB_OPENED });
						});
					}, DEBOUNCE_DELAY);
				}
			} else {
				this.removeEventsListeners();
				this.resetTimer();
				this.handleLogoutTimer();
			}
		},
		
		/**
		 * Event listener for all events from the list
		 */
		activityEventListener: debounce(function () {
			this.resetTimer();
			this.handleLogoutTimer();
		}, DEBOUNCE_DELAY),

		/**
		 * Resets the timer
		 */
		resetTimer () {
			if (this.timer) clearTimeout(this.timer);
		},

		/**
		 * Sets timeout each time when a listener invokes the method
		 */
		handleLogoutTimer () {
			this.timer = setTimeout(() => {
				this.resetTimer();
				this.removeEventsListeners();
				this.removeVisibilityEventListener();

				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.ready
						.then(() => {
							const controllerExists = get(navigator, 'serviceWorker.controller');

							if (controllerExists) {
								navigator?.serviceWorker?.controller?.postMessage({ type: ServiceWorkerType.LOGOUT });
							} else {
								this.$logout();
							}
						});
				} else {
					this.$logout();
				}
				
			}, INACTIVITY_LOGOUT_TIME);
		},

		/**
		 * Adds listeners for all events from the list
		 */
		addEventsListeners () {
			EVENTS_FOR_LISTENING.forEach(event => {
				document.addEventListener(event, this.activityEventListener);
			});
		},

		/**
		 * Removes listeners for all events from the list
		 */
		removeEventsListeners () {
			EVENTS_FOR_LISTENING.forEach(event => {
				document.removeEventListener(event, this.activityEventListener);
			});
		},

		removeVisibilityEventListener () {
			document.removeEventListener(VISIBILITY_CHANGE_EVENT, this.visibilityEventListener);
		},

		/**
		 * Init events listeners for cheching user activity
		 * Init a listener for checking whether the tab is visibile or hidden
		 */
		initInactivityLogoutEventsListeners () {
			this.addEventsListeners();
			document.addEventListener(VISIBILITY_CHANGE_EVENT, this.visibilityEventListener);

			if (document.visibilityState === DOCUMENT_STATE_VISIBLE) {
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.ready
						.then(() => {
							navigator?.serviceWorker?.controller?.postMessage({ type: ServiceWorkerType.TAB_OPENED });
							this.activityEventListener();
						});
				} else {
					this.activityEventListener();
				}
			}
		}
  },
}