<script>
    import Model from 'Core/mixins/Model';

    export default {
        /**
         * Component name.
         */
        name: 'BaseFormatter',

        /**
         * Mixins.
         */
        mixins: [
            Model,
        ],

        /**
         * Computed properties.
         */
        computed: {
            /**
             * Formatted value.
             *
             * @returns {string}
             */
            formatted () {
                return this.value;
            },
        },

        /**
         * Render the component.
         *
         * @returns {VNode}
         */
        render () {
            return this._v(this.formatted);
        }
    }
</script>