import phasesSidebar from './phases-sidebar';
import sidebar from './sidebar';

/**
 * Types.
 */
const types = {};

/**
 * State
 *
 * @type {{}}
 */
const state = {};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {

};

/**
 * Actions.
 */
const actions = {};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {};

/**
 * Default modules
 */
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
    modules: {
        phasesSidebar,
        sidebar,
    },
};