import runtime 						from 'serviceworker-webpack5-plugin/lib/runtime';
import { webPushGateway }	from 'Core/api/WebPushGateway';
import ServiceWorkerType 	from 'Portal/models/ServiceWorkerType';

export const serviceWorkerRegistration = async () => {
	/**
	 * Service worker registration after the app was created
	 */
	try {
		if ('serviceWorker' in navigator) {
			return await runtime.register();
		}
	} catch {
		console.log("Service worker hasn't been registered!");
	}
}

export const askNotificationPermission = async (context) => {
	try {
		const permission = await Notification.requestPermission();

		if (permission !== 'granted') return await Promise.reject();

		const registration = await runtime.register();

		const subscription = await subscribeToServiceWorker(registration);

		const data = JSON.parse(JSON.stringify(subscription));

		const promise = webPushGateway.sendSubscription(data);

		return context.$resolve(promise);
	} catch {
		console.log('Permission was not granted.');
	}
}

/**
 * Listens "message" event for all opened tabs
 * If the user is not on the login page logout the user
 * @param context 
 */
export const addTabsMessageListener = (context) => {
	navigator.serviceWorker.addEventListener('message', function(event) {
		if ((context.$route.name !== 'portal.login') && event.data.type === ServiceWorkerType.LOGOUT) {
			context.removeEventsListeners(); 					// The method comes from "HandleInactivity" mixin
			context.removeVisibilityEventListener(); 	// The method comes from "HandleInactivity" mixin
			context.$logout(); 												// The method comes from "HandleLogout" mixin
		}

		/**
		 * Resets the logout time on each tab when a new tab was opened
		 */
		if (event.data.type === ServiceWorkerType.TAB_OPENED) {
			context.resetTimer();	// The method comes from "HandleInactivity" mixin
		}
	});
}

export const subscribeToServiceWorker = (registration) => {
	return registration.pushManager
		.getSubscription()
		.then(function(existedSubscription) {
			if (existedSubscription === null) {
				console.log("No subscription detected, make a request.");
				const subscribeOptions = {
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_SUBSCRIPTION_APPLICATION_SERVER_KEY)
				};

				return registration.pushManager.subscribe(subscribeOptions);
			} else {
				return existedSubscription;
			}
		});
}

/**
 * Convert firebase vapid key to uint8 object
 * @param base64String
 * @returns {Uint8Array}
 */
function urlBase64ToUint8Array(base64String) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, "+")
		.replace(/_/g, "/");

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
