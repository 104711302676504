<template>
  <span />
</template>

<script>
    export default {
        /**
         * Component name.
         */
        name: 'Icon',

        /**
         * Available properties.
         */
        props: {
            /**
             * Custom classes.
             */
            customClass: {
                type: String,
                default: '',
            },
        },
    }
</script>

<style scoped>

</style>
