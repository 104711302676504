import {head} from "Core/utils/dom";
import {mapGetters} from "vuex";
import {cdnUrl} from "Core/utils/http";

export default {
    /**
     * Computed properties
     */
    computed: {
        /**
         * Map getters
         */
        ...mapGetters({
            clientStyleGuideSettingsSchema: 'portal/styleGuides/clientStyleGuideSettingsSchema',
        }),
    },

    /**
     * Methods
     */
    methods: {
        /**
         * Get favicon from style guides and set it
         */
        setFaviconFromStyleguide () {
            const { options } = this.clientStyleGuideSettingsSchema.get('favicon');
            
            if (!options.url) {
                return;
            }
    
            let icon = head().querySelector("link[rel~='icon']");
    
            if (! icon) {
                icon = document.createElement('link');
                icon.rel = 'icon';
                head().appendChild(icon);
            }
            
            icon.href = cdnUrl(options.url);
        }
    }
}