import { optional } from 'Core/utils/object';

/**
 * Types.
 */
const types = {};

/**
 * State
 *
 * @type {{}}
 */
const state = {};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
    /**
     * Get person availabilities
     *
     * @param state
     * @param getter
     * @param rootState
     * @param rootGetters
     *
     * @return {[]}
     */
    availabilities (state, getter, rootState, rootGetters) {
        return optional(rootGetters['portal/person/person']).availabilities || [];
    },
};

/**
 * Actions.
 */
const actions = {};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {};

/**
 * Default modules
 */
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
    modules: {
    },
};