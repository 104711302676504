<script>
    import WithStyleguideSchema from 'Portal/mixins/WithStyleguideSchema';

    export default {
        /**
         * Component name
         */
        name: "BaseLogo",

        /**
         * Mixins
         */
        mixins: [
            WithStyleguideSchema,
        ],

        /**
         * Computed properties
         */
        computed: {
            /**
             * Override in ur inherit components
             *
             * @return {StyleDefinition|null}
             */
            definition () {
                return null;
            },

            /**
             * Get url for the logo
             *
             * @return {string|null}
             */
            url () {
                if (! this.definition) {
                    return null;
                }

                return this.definition.getUrl();
            },
        },

        /**
         * Render function
         *
         * @param createElement
         * @param context
         * @return {null}
         */
        render(createElement, context) {
            return null;
        },
    }
</script>

<style scoped>

</style>