import UserServiceRequest from './requests/UserServiceRequest';
import BaseGateway from './BaseGateway';
import config from 'Core/config';
import PortalServiceRequest from "Core/api/requests/PortalServiceRequest";
import AuthorizerRequest from "Core/api/requests/AuthorizerRequest";
import moment from "moment";

/**
 * Gateway to authentication
 */
export default class AuthGateway extends BaseGateway {
    /**
     * Make login request
     *
     * @param username
     * @param password
     * @param includes
     * @param params
     *
     * @return {Promise<*>}
     */
    login (username, password, includes = [], params = []) {
        return this.usingRequest(new PortalServiceRequest())
            .withoutHeaders(['Authorization'])
            .withBody({ username, password, params })
            .withIncludes(includes)
            .post('login');
    }

    /**
     * Make logout request
     * When logout is performed other refresh tokens of the user cannot be used
     *
     * @return {Promise<*>}
     */
    logout () {
        return this.request().delete('logout')
    }

    /**
     * Refresh access token
     *
     * @return {Promise<*>}
     */
    refresh (refreshToken, accessToken) {
        return this.usingRequest(new AuthorizerRequest())
            .withoutHeaders()
            .withBody({ refreshToken, accessToken })
            .post(config.refreshToken.path)
            .then(response => {
                response.data = {
                    access_token: response.data.idToken,
                    refresh_token: response.data.refreshToken,
                    token_type: response.data.tokenType,
                    expires_in: moment(response.data.expiresAt).unix() - moment().unix(),
                };
  
                return response;
            });
    }

    /**
     * Make new request object
     *
     * @return {UserServiceRequest}
     */
    makeRequest () {
        return new UserServiceRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {AuthGateway}
 */
export const authGateway = new AuthGateway();