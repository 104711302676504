export default {
    /**
     * Available properties.
     */
    props: {
        /**
         * True if modal is visible.
         */
        visible: {
            type: Boolean,
            default () {
                return false;
            },
        },
    },

    /**
     * Reactive data
     *
     * @return {{isVisible: Boolean}}
     */
    data () {
        return {
            isVisible: this.visible,
        };
    },

    /**
     * Available methods.
     */
    methods: {
        /**
         * Open the component.
         */
        open () {
            if (this.isVisible) {
                return;
            }

            this.isVisible = true;
            this.$emit('update:visible', true);
            this.$emit('open');
        },

        /**
         * Close the component.
         */
        close () {
            if (! this.isVisible) {
                return;
            }

            this.isVisible = false;
            this.$emit('update:visible', false);
            this.$emit('close');
        },

        /**
         * Toggle visibility of the component.
         */
        toggleVisibility () {
            if (this.isVisible) {
                this.close();
            } else {
                this.open();
            }
        },
  
        /**
         * Set visiblity
         * @param val
         */
        setVisibility (val) {
            if (val) {
                this.close();
            } else {
                this.open();
            }
        }
    },
};
