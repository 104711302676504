<template>
  <div
    v-show="isVisible"
    class="absolute w-full h-full"
  >
    <el-drawer
      class="pages-sidebar"
      direction="ltr"
      :visible.sync="isVisible"
      :modal="false"
      :with-header="false"
      :before-close="handleBeforeClose"
    >
      <div
        class="pages-sidebar__header flex items-center px-6"
        @click="toggleSidebar({ name: 'PagesSidebar' })"
      >
        <router-link :to="{ name: 'portal.home' }">
          <secondary-logo class="h-8" />
        </router-link>
      </div>
      <div
        v-scroll-lock="isVisible"
        class="pages-sidebar__content"
        :class="`${$screen.mobile.vendor} ${$screen.mobile.orientation}`.trim()"
      >
        <navigation
          :items="menuPages"
          @navigation-change="toggleSidebar({ name: 'PagesSidebar' })"
        />
      </div>
      <div class="pages-sidebar__footer">
        <div class="pages-sidebar__footer-content">
          <div
            v-if="settings.email || settings.phone"
            class="flex flex-col justify-center items-center pt-2 pb-3 flex-grow-0 rounded-lg bg-gray-100 pages-sidebar__footer-contacts"
          >
            <div
              v-if="settings.email"
              class="flex items-center text-gray-600 pages-sidebar__footer-item"
            >
              <letter-icon class="w-4 h-4 mr-2" />
              {{ settings.email }}
            </div>
            <div
              v-if="settings.phone"
              class="flex items-center text-gray-600 pt-3 pages-sidebar__footer-item"
            >
              <phone-icon class="w-4 h-4 mr-2" />
              <phone-formatter :value="settings.phone" />
            </div>
          </div>
          <div class="mt-4 flex justify-center color-blue-500-10 pages-sidebar__footer-copyright">
            &#169; 2021 2Solar Software
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Navigation from 'Portal/components/sidebars/navigation/Navigation';
import PhoneFormatter from 'Core/components/data/formatters/PhoneFormatter';
import PhoneIcon from 'Core/components/icons/PhoneIcon';
import LetterIcon from 'Core/components/icons/LetterIcon';
import Visible from 'Core/mixins/Visible';
import SecondaryLogo from 'Portal/components/images/SecondaryLogo';

export default {
  /**
   * Component name
   */
  name: "PagesSidebar",

  /**
   * Registered components
   */
  components: { Navigation, LetterIcon, PhoneIcon, PhoneFormatter, SecondaryLogo },

  mixins: [
    Visible,
  ],

  /**
   * Computed props
   */
  computed: {
    /**
     * Vuex
     */
    ...mapGetters('portal/pages', ['menuPages']),
    ...mapGetters('portal/layout/sidebar', ['activeSideBar']),
    ...mapGetters('portal/template', ['template']),

    /**
     * Settings of the template
     *
     * @return {{copyright: string, phone: string, email: string}}
     */
    settings () {
      return this.$optional(this.template?.settings_map);
    },
  },

  /**
   * Watchers
   */
  watch: {
    activeSideBar: 'handleActiveSidebar',
  },

  methods: {
    ...mapActions('portal/layout/sidebar', [
      'toggleSidebar',
    ]),

    handleActiveSidebar(value) {
      this.isVisible = value === "PagesSidebar";
    },

    handleBeforeClose() {
      this.toggleSidebar({});
    },
  },
}
</script>

<style type="text/scss" lang="scss">
@import 'src/styles/module/portal/variables';

.pages-sidebar {
  height: 100vh;

  .el-drawer {
    width: 197px !important;

    @media (max-width: 640px) {
      width: 75% !important;
    }

    @screen tablet {
      width: 266px !important;
    }

    @screen laptop {
      width: 266px !important;
    }

    &__open {
      background-color: rgba(255, 255, 255, .75);
    }
  }

  &__header {
    a[href] {
      outline: none;
    }

    height: $mobile-header-height;

    @screen tablet {
      height: $tablet-header-height;
    }

    @screen laptop {
      height: $laptop-header-height;
    }
  }

  &__content {
    @apply flex flex-col overflow-y-auto overflow-x-hidden;

    height: calc(100vh - #{$sidebar-footer-height} - #{$mobile-header-height});

    &.portrait {
      &.iphone {
        height: calc(100vh - #{$sidebar-footer-height} - #{$mobile-header-height} - #{$iphone-portrait-height-delta});
      }

      &.android {
        height: calc(100vh - #{$sidebar-footer-height} - #{$mobile-header-height} - #{$android-portrait-height-delta});
      }
    }

    &.landscape {
      &.iphone {
        height: calc(100vh - #{$sidebar-footer-height} - #{$mobile-header-height} - #{$iphone-landscape-height-delta});
      }

      &.android {
        height: calc(100vh - #{$sidebar-footer-height} - #{$mobile-header-height} - #{$android-landscape-height-delta});
      }
    }

    @screen tablet {
      height: calc(100vh - #{$sidebar-footer-height} - #{$tablet-header-height});
    }
  }

  &__footer {
    height: $sidebar-footer-height;
    @apply flex items-end;
  }

  &__footer-content {
    @apply text-xs px-4 w-full py-2;
  }
}
</style>
