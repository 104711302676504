import concat from "lodash/concat";
import auth from "./auth";
import requests from './requests';
import person from './person';

const routes = [
    {
        path: '*',
        redirect: {
            name: 'portal.login',
        },
    }
];

/**
 * Delayed routes should be registered once portal routes
 * has been bootstrapped. Because vue-router start matching
 * current route before vue-app hit 'created' hook. So we
 * don't want that happen because router middleware may
 * not have enough data to perform valid access check.
 * For example authentication middleware.
 */
export default concat(
    auth,
    requests,
    person,
    routes,
);
