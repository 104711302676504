import {mapActions, mapGetters} from 'vuex';
import pagesConfig from '../../config/pages';
import delayedRoutes from '../../router/delayed';
import {optional} from 'Core/utils/object';
import PageCategory from '../../models/PageCategory';
import PageType from '../../models/PageType';
import slugify from 'slugify';
import set from 'lodash/set'
import clone from 'lodash/cloneDeep';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import {interpolate} from "Core/utils/string";

/**
 * An array of registered routes to prevent duplication
 *
 * @type {[*]}
 */
const registeredRoutes = [];

/**
 * Create page slug
 *
 * @param page
 * @return {string|null}
 */
const buildPageSlug = (page) => {
  const title = page.settings_map['page-title'];
  
  if (!title) {
    return null;
  }
  
  return slugify(title).toString().toLowerCase();
}

/**
 * Create route
 *
 * @param source
 * @return {function(...[*]=)}
 */
const createRoute = (source) => (page) => {
  const pageConfigs = source[optional(page.type).slug];
  
  if (!pageConfigs) {
    return null;
  }
  
  const route = pageConfigs[page.layout || 1];
  
  if (!route) {
    return null;
  }
  
  /*
   * Set the route object so that
   * in application we can easy
   * redirect to specific page
   * using page object
   */
  page.route = route;
  
  const newRoute = clone(route);
  set(newRoute, 'meta.page', page);
  
  return newRoute;
}

export default {
  /**
   * Computed properties
   */
  computed: {
    /**
     * Map getters
     */
    ...mapGetters('portal/template', ['template']),
    ...mapGetters('portal/pages', ['pages', 'menuPages']),
  },
  
  /**
   * Mixin methods
   */
  methods: {
    /**
     * Vue actions
     */
    ...mapActions('portal/pages', [
      'loadHeaderMenuPages',
    ]),
    
    /**
     * Bootstrap application routes
     *
     * @return {Promise<*>}
     */
    bootstrapRoutes() {
      this.registerRoutes(this.pages);

      if (this.template) {
        this.registerRoutes(this.menuPages);
      }
      
      return Promise.resolve();
    },
    
    /**
     * Bootstrap routes that are delayed
     *
     * @return {Promise<*>}
     */
    bootstrapDelayedRoutes() {
      return Promise.resolve(
        this.addRoutes(delayedRoutes)
      );
    },
    
    /**
     * Register template routes
     */
    registerRoutes(pages) {
      const routes = [].concat(
        this.buildFunctionalRoutes(pages),
        this.buildCustomRoutes(pages),
        this.template ? this.buildHomePageRoutes(pages) : [],
      )
  
      this.addRoutes(routes);
    },
    
    /**
     * Build functional routes
     *
     * @param data
     *
     * @return [*]
     */
    buildFunctionalRoutes(data) {
      return data
      .filter(page => page.type.slug !== PageType.CUSTOM && page.type.slug !== PageType.HOME)
      .map(createRoute(pagesConfig.functional))
      .filter(x => x);
    },
    
    /**
     * Build routes for custom pages
     *
     * @para data
     *
     * @return [*]
     */
    buildCustomRoutes(data) {
      const generator = createRoute(pagesConfig);
      
      return data
      .filter(page => {
        return page.category.name === PageCategory.MENU &&
          page.type.slug === PageType.CUSTOM;
      })
      .map(page => {
        const route = generator(page);
        
        if (route) {
          route.name += '.' + page.id;
          
          // Update route object of the page
          page.route = route;
          page.routeParams = {
            slug: buildPageSlug(page),
            id: page.id,
          };
          
          route.path = interpolate(route.path, page.routeParams);
          
          return route;
        }
        
        return null;
      })
      .filter(x => x);
    },
    
    /**
     * Build pages to routes that is home page category
     *
     * @param data
     * @return [*]
     */
    buildHomePageRoutes(data) {
      const mainHomePage = find(data, ['id', this.template.home_page_id]);
      const homeGenerator = createRoute(pagesConfig.functional);
      
      const routes = data.filter(page => page !== mainHomePage && page.type.slug === PageType.HOME)
      .map((page) => {
        const route = homeGenerator(page);
        
        route.name += '.' + page.id;
        route.path = interpolate(route.path, {
          slug: buildPageSlug(page),
          id: page.id,
        });
        
        page.route = route;
        
        return route;
      });
      
      if (mainHomePage) {
        const mainGenerator = createRoute(pagesConfig);
        routes.push(mainGenerator(mainHomePage));
      }
      
      return routes;
    },
    
    /**
     * Add routes to vue-router
     *
     * @param routes
     */
    addRoutes(routes) {
      const uniqueRoutes = uniqBy(routes, 'name').filter((route) => !registeredRoutes.includes(route.name));
      
      this.$router.addRoutes(uniqueRoutes);
      
      uniqueRoutes.forEach((route) => registeredRoutes.push(route.name));
    }
  }
}