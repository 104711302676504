import Request from './Request';
import config from '../../config/index';

export default class ClientServiceRequest extends Request {
    /**
     * Constructor
     */
    constructor() {
        super(config.api.clientService);
    }
}