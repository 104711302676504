<template>
  <ul class="two-solar-navigation">
    <navigation-item
      v-for="item in items"
      :key="item.id"
      :value="item"
      @navigation-item-select="click(item)"
    />
  </ul>
</template>

<script>
import NavigationItem from 'Portal/components/sidebars/navigation/NavigationItem';

export default {
  name: "Navigation",

  components: { NavigationItem },

  props: {
    items: {
      type: Array,
      required: true,
    }
  },

  methods: {
    click(input) {
      this.$emit('navigation-change', input);
    }
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
</style>
