<template>
  <div
    class="header-text"
    :style="headerTextStyles"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
import WithStyleguideSchema from "Portal/mixins/WithStyleguideSchema";

export default {
  name: "HeaderTex",

  mixins: [
    WithStyleguideSchema,
  ],

  props: {
    defaultFontSize: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headerTextStyles () {
      const headerStyleGuide = this.styleGuideSchema.get('header').setPrefix('header');

      if (!headerStyleGuide) return [];

      return [
        headerStyleGuide.getTextColor(),
        headerStyleGuide.getFontFamily(),
        headerStyleGuide.getFontStyle(),
        ...(!this.defaultFontSize ? [ headerStyleGuide.getFontSize() ] : []),
      ]
    },
  }
}
</script>

<style type="text/scss" lang="scss">
  @mixin header-text {
    font-size: var(--sg-header-font-size, 14px);
    color: var(--sg-header-text-color, #1A2021);
    font-weight:  var(--sg-header-font-weight, 400);
    font-family: var(--sg-header-font-family, 'Open sans');
  }
  .header-text {
    @include header-text;

    * {
      @include header-text;
    }
  }
</style>