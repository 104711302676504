import {mapActions, mapGetters} from 'vuex';
import {logger} from 'Core/services/analytics/EventLogger';
import PortalError from "Portal/util/PortalError";

export default {
  /**
   * Computed properties
   */
  computed: {
    /**
     * Map getters
     */
    ...mapGetters({
      template: 'portal/template/template',
      hasUser: 'core/user/hasUser',
      cookieUsageAnalytical: 'core/preferences/cookieUsageAnalytical',
      latestRequestWithPhase: 'portal/requests/latestRequestWithPhase',
    }),
  },
  
  /**
   * Mixin methods
   */
  methods: {
    ...mapActions({
      loadPreferences: 'core/preferences/loadPreferences',
      getClient: 'core/client/getClient',
      loadRequests: 'portal/requests/loadRequests',
    }),
    
    /**
     * Bootstrap vuex data
     *
     * @return {Promise<*>}
     */
    bootstrapVuex() {
      const promises = [];
      
      if (this.hasUser) {
        promises.push(this.loadPreferences().then(this.handlePreferences));

        const requestsPromise = this.loadRequests().then(() => {
          if (! this.template.home_page_id && ! this.latestRequestWithPhase) {
            throw new PortalError(this.$trans('portal.messages.error_page.message'));
          }
        });
        promises.push(requestsPromise);
      }
      
      return Promise.all(promises);
    },
    
    /**
     * Handle loaded preferences
     */
    handlePreferences() {
      logger.init(!this.cookieUsageAnalytical);
    },
  },
}
