export default class PageCategory {
    static get GENERAL () {
        return 'general';
    }

    static get MENU () {
        return 'menu';
    }

    static get PHASE_RELATED () {
        return 'phase-related';
    }
}