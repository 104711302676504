export default {
    app: {
        // Url of the application
        url: process.env.VUE_APP_URL,
        cdn: process.env.VUE_APP_CDN_URL,
    },

    /**
     * The url for services
     */
    api: {
        portalService: process.env.VUE_APP_PORTAL_SERVICE_API,
        userService: process.env.VUE_APP_USER_SERVICE_API,
        drawingService: process.env.VUE_APP_DRAWING_SERVICE_API,
        requestService: process.env.VUE_APP_REQUEST_SERVICE_API,
        clientService: process.env.VUE_APP_CLIENT_SERVICE_API,
        offerService: process.env.VUE_APP_OFFER_SERVICE_API,
        offerServiceV2: process.env.VUE_APP_OFFER_SERVICE_API_V2,
        twoSolar: process.env.VUE_APP_TWO_SOLAR_API,
        twoSolarObsolete: process.env.VUE_APP_TWO_SOLAR_API_OBSOLETE,
        bucketManager: process.env.VUE_APP_BUCKET_MANAGER_URL,
        personalization: process.env.VUE_APP_PERSONALIZATION_API,
        companyService: process.env.VUE_APP_COMPANY_SERVICE_API,
        chatService: process.env.VUE_APP_CHAT_SERVICE_API,
        webPushService: process.env.VUE_APP_WEB_PUSH_SERVICE_API,
        authorizerService: process.env.VUE_APP_AUTHORIZER_SERVICE_API,
    },

    apiKey: {
        twoSolarKeyObsolete: process.env.VUE_APP_TWO_SOLAR_API_KEY_OBSOLETE,
    },

    /**
     * Refresh token config
     */
    refreshToken: {
        path: 'refresh',
        fullUrl: process.env.VUE_APP_AUTHORIZER_SERVICE_API + 'refresh',
    },

    /**
     * The fallback locale of the application
     */
    fallbackLocale: 'en',
}
