import strSlug from 'slugify';
import {isUndefinedOrNull, optional} from 'Core/utils/object';
import {cdnUrl} from "Core/utils/http";

export default class StyleDefinition {
    /**
     * Constructor
     *
     * @param options
     * @param prefix
     */
    constructor(options = {}, prefix = '') {
        this.options = options;
        this.prefix = prefix;
        this.layouts = {};
    }

    /**
     * Check whether style definition is empty
     *
     * @return {boolean}
     */
    isEmpty () {
        return Object.keys(this.options).length === 0;
    }

    /**
     * Merge options
     *
     * @param options
     * @return {StyleDefinition}
     */
    mergeOptions (options) {
        Object.assign(this.options, options);

        return this;
    }

    /**
     * Set prefix for variable definition
     *
     * @param prefix
     * @return {StyleDefinition}
     */
    setPrefix (prefix) {
        this.prefix = prefix;

        return this;
    }

    /**
     * Set layouts
     *
     * @param layouts
     * @return {StyleDefinition}
     */
    setLayouts (layouts) {
        this.layouts = layouts;

        return this;
    }

    /**
     * Get background color
     *
     * @return {string}
     */
    getBackgroundColor () {
        return this._composeVariable('bg-color', this.getColorDefinition('background'));
    }

    /**
     * Get border color
     *
     * @return {string}
     */
    getBorderColor () {
        return this._composeVariable('border-color', this.getColorDefinition('border'));
    }

    /**
     * Get text color
     *
     * @return {string}
     */
    getTextColor () {
        return this._composeVariable('text-color', this.getColorDefinition('text'));
    }
  
    /**
     * Get text decoration
     *
     * @return {*}
     */
    getTextDecoration () {
        return this._composeVariable('text-decoration', this._getScopeOptions('text').decoration)
    }

    /**
     * Get shadow color
     *
     * @return {string}
     */
    getShadowColor () {
        return this._composeVariable('shadow-color', this.getColorDefinition('shadow'));
    }

    /**
     * Get font family
     *
     * @return {string}
     */
    getFontFamily () {
        return this._composeVariable('font-family', this._getScopeOptions('font').family);
    }

    /**
     * Get font size
     *
     * @return {string}
     */
    getFontSize () {
        return this._composeVariable('font-size', this._getScopeOptions('font').size, 'px');
    }

    /**
     * Get font weight or style
     *
     * @return {string|null}
     */
    getFontStyle () {
        return this._composeVariable('font-weight', this._getScopeOptions('font').style);
    }

    /**
     * Get url of an asset
     *
     * @return {string}
     */
    getUrl () {
        return cdnUrl(this.options.url);
    }

    /**
     * Get url as an css variable
     *
     * @return {*}
     */
    getImageUrl () {
        return this._composeVariable('image', `url(${this.getUrl()})`);
    }

    /**
     * Get border radius variable
     *
     * @param {string} option
     * @return {*}
     */
    getBorderRadius (option = 'border') {
        return this._composeVariable('border-radius', this._getBorderRadiusDefinition(option), 'px');
    }

    /**
     * Get background size variable
     *
     * @param defaultValue
     * @return {*}
     */
    getBackgroundSize (defaultValue = 'inherit') {
        const isFixed = this.options.position === 'fixed';

        if (isFixed) {
            return this._composeVariable('bg-size', 'cover');
        }

        return this._composeVariable('bg-size', defaultValue);
    }

    /**
     * Get background position
     *
     * @return {*}
     */
    getBackgroundPosition () {
        return this._composeVariable('bg-position', this.options.position);
    }

    /**
     * Get background attachment
     *
     * @return {null|*}
     */
    getBackgroundAttachment (isMobileOrTablet = false) {
        const isFixed = this.options.position === 'fixed';

        if (isFixed && !isMobileOrTablet) {
            return this._composeVariable('bg-attachment', 'fixed');
        }

        return this._composeVariable('bg-attachment', 'inherit');
    }

    /**
     * Get flex positioning
     *
     * @return {*}
     */
    getFlexPositioning () {
        const positioningMap = {
            center: 'center',
            left: 'flex-start',
            right: 'flex-end',
        };

        return this._composeVariable('flex-positioning', positioningMap[this.options.position]);
    }

    /**
     * Get the layout options for current definition
     *
     * @return {{border: {radius: number}}}
     * @private
     */
    _getLayoutOption () {
        return this.layouts[this.options.layout];
    }

    /**
     * Return the border radius of the definition
     *
     * @param {string} option
     * @return {number}
     * @private
     */
    _getBorderRadiusDefinition (option) {
        const layout = this._getLayoutOption();

        if (! layout) {
            return 0;
        }

        return optional(layout[option]).radius || 0;
    }

    /**
     * Get the options of scope
     *
     * @param scope
     * @return {*}
     * @private
     */
    _getScopeOptions (scope) {
        return this.options[scope] || {};
    }

    /**
     * Get the color definition for scope
     *
     * @param scope
     *
     * @return {string}
     */
    getColorDefinition (scope) {
        const options = this._getScopeOptions(scope);

        return options.color;
    }

    /**
     * Get font family definition
     *
     * @param scope
     * @return {string}
     * @private
     */
    _getFontFamilyDefinition (scope) {
        return this._getScopeOptions(scope).family;
    }

    /**
     * Compose name of the variable with value
     * Example this._composeVariable('color', 'red')
     * -> --sg-color:red;
     *
     * @param property
     * @param value
     * @param unit
     * @return {*}
     * @private
     */
    _composeVariable (property, value, unit) {
        if (isUndefinedOrNull(value)) {
            return null;
        }

        let name = '--sg-';
        if (this.prefix) {
            name += this.prefix + '-';
        }

        const variableName = name + strSlug(property);
        const variableValue = value + (unit || '');

        return { [variableName]: variableValue };
    }
}