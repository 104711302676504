import user from './modules/user';
import preferences from './modules/preferences';
import client from './modules/client';

/**
 * Types.
 */
const types = {};

/**
 * State
 *
 * @type {{}}
 */
const state = {};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {};

/**
 * Actions.
 */
const actions = {};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
    modules: {
        user,
        client,
        preferences,
    }
};