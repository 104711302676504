import Request from './Request';
import config from '../../config/index';

export default class RequestServiceRequest extends Request {
    /**
     * Constructor
     */
    constructor() {
        super(config.api.requestService);
    }
}