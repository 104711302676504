import auth from '../../../../core/router/middleware/auth';
import analytics from 'Portal/router/middleware/analytics';

export default [
    {
        path: '/requests/:id',
        name: 'portal.request.show',
        components: {
            header: () => import('../../components/headers/AppHeader'),
            footer: () => import('../../components/footers/AppFooter'),
            default: () => import('../../pages/request/RequestPhasesPage'),
        },
        meta: {
            middleware: { attach: [ auth, analytics ] },
            sideBars: {
                triggers: {
                    container: {
                        classNames: 'phasesWizard:hidden',
                    },
                },
            },
        },
    },
];
