import { mapActions } from 'vuex';
import BaseGateway from 'Core/api/BaseGateway';
import { userGateway } from 'Core/api/UserGateway';
import { silence } from 'Core/utils/function';
import InteractsWithAccessToken from 'Core/mixins/InteractsWithAccessToken';
import { logger } from 'Core/services/analytics/EventLogger';
import { isMagicLinkRequest } from "Core/utils/http";

export default {
    /**
     * Mixins
     */
    mixins: [
        InteractsWithAccessToken,
    ],

    /**
     * Mixin methods
     */
    methods: {
        /**
         * Map actions
         */
        ...mapActions('core/user', [
            'setAuthenticatedUser',
        ]),
        ...mapActions('portal/person/address', [
            'loadPersonAddresses',
        ]),

        /**
         * Bootstrap authenticated user
         *
         * @return Promise<*>
         */
        bootstrapAuthenticatedUser () {
            if (isMagicLinkRequest()) {
                return Promise.resolve();
            }

            try {
                this.restoreTokens()
                this.restoreAuthenticatedUser();
            } catch (e) {
                return Promise.reject(e);
            }

            return this.refreshData();
        },

        /**
         * Restore authenticated user from storage
         */
        restoreAuthenticatedUser () {
            const user = this.$ls.get('user');

            if (! user) {
                throw new Error('The user cannot be restored from storage.')
            }

            this.setAuthenticatedUser(user);
        },

        /**
         * Restore tokens
         */
        restoreTokens () {
            const tokenData = this.getAccessToken();

            if (! tokenData) {
                throw new Error('The token cannot be restored from storage.')
            }

            BaseGateway.addDefaultHeader(
                'Authorization',
                `${tokenData.token_type} ${tokenData.access_token}`
            );
        },

        /**
         * Refresh authenticated user data
         *
         * @return {Promise<*>}
         */
        refreshData () {
            const userPromise = this.refreshAuthenticatedUser();
            const addressesPromise = this.loadPersonAddresses();

            return Promise.all([
                userPromise,
                addressesPromise,
            ]);
        },

        /**
         * Refresh authenticated user from backend
         */
        refreshAuthenticatedUser () {
            return userGateway.getAuthenticatedUser([
                'personProfile.bankAccount',
                'personProfile.availabilities'
            ])
                .then(this.handleUserResponse)
                .catch(silence);
        },

        /**
         * Handle user response
         *
         * @param data
         */
        handleUserResponse ({ data }) {
            this.setAuthenticatedUser(
                data.data
            );

            // In the portal the user id has to be person id
            logger.setUserIdentifier(data.data.personProfile.id);
        },
    }
}