<template>
  <div class="error-page">
    <missing-data-container>
      <heading2 slot="title">
        <strong>{{ $trans('portal.messages.error_page.title.0') }}</strong>
        <br>
        <strong>{{ $trans('portal.messages.error_page.title.1') }}</strong>
      </heading2>
      <heading3 slot="content">
        <!-- P A G E  T E X T -->
        {{ pageText }}

        <div v-if="template">
          <div
            v-if="settings.email"
            class="flex items-center justify-center pt-5"
          >
            <letter-icon class="w-4 h-4 mr-2" />
            <heading3>
              <a
                :href="'mailto:' + settings.email"
                target="_blank"
                v-text="settings.email"
              />
            </heading3>
          </div>
          <div
            v-if="settings.phone"
            class="flex items-center justify-center pt-5"
          >
            <phone-icon class="w-4 h-3 mr-2" />
            <heading3>
              <a
                :href="'tel:' + settings.phone"
                v-text="settings.phone"
              />
            </heading3>
          </div>
        </div>
      </heading3>
    </missing-data-container>
  </div>
</template>

<script>
import MissingDataContainer from 'Portal/components/content/request/MissingDataContainer';
import { mapGetters } from 'vuex';
import LetterIcon from 'Core/components/icons/LetterIcon';
import PhoneIcon from 'Core/components/icons/PhoneIcon';
import Heading2 from 'Portal/components/text/Heading2';
import Heading3 from 'Portal/components/text/Heading3';
import InteractsWithPageTitle from 'Portal/mixins/InteractsWithPageTitle';
import PortalError from "Portal/util/PortalError";

const ERROR_PAGE = {
  TYPE: 'error',
  TEXT: 'page-text',
  TITLE: 'page-title'
}

export default {
  name: 'ErrorPage',

  components: { Heading3, Heading2, PhoneIcon, LetterIcon, MissingDataContainer },

  mixins: [ InteractsWithPageTitle ],

  props: {
    error: {
      type: Error,
      required: true,
    },
  },

  /* DATA */
  data () {
    return {
      defaultErrorPage: {
        [ERROR_PAGE.TITLE]: 'Error',
        [ERROR_PAGE.TEXT]: this.$trans('portal.messages.error_page.message')
      }
    }
  },

  /**
   * Computed properties
   */
  computed: {
    /**
     * Vuex getters
     */
    ...mapGetters('portal/template', ['template']),

    ...mapGetters('portal/pages', ['pages']),

    errorPage () {
      if (this.error instanceof PortalError) {
        return {
          [ERROR_PAGE.TITLE]: 'Error',
          [ERROR_PAGE.TEXT]: this.error.message,
        };
      }

      return this.pages.find(page => page.type?.slug === ERROR_PAGE.TYPE)?.settings_map || this.defaultErrorPage
    },

    pageTitle () {
      return this.errorPage[ERROR_PAGE.TITLE];
    },

    pageText () {
      return this.errorPage[ERROR_PAGE.TEXT];
    },

    /**
     * Settings of the template
     *
     * @return {{copyright: string, phone: string, email: string}}
     */
    settings () {
      return this.$optional(this.template?.settings_map)
    },
  },

  /* HOOKS */
  created () {
    this.setPageTitle(this.pageTitle)
  },
}
</script>

<style type="text/scss" lang="scss" scoped>
@import "src/styles/module/portal/pages/error-page";
</style>
