import { render, staticRenderFns } from "./LetterIcon.vue?vue&type=template&id=09dd681f&scoped=true&"
import script from "./LetterIcon.vue?vue&type=script&lang=js&"
export * from "./LetterIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09dd681f",
  null
  
)

export default component.exports