import moment from 'moment';

export default {
    methods: {
        /**
         * Get access token from storage
         *
         * @return {*}
         */
        getAccessToken () {
            return this.$ls.get('token');
        },

        /**
         * Check if access token exists
         *
         * @return {boolean}
         */
        hasAccessToken () {
            return !! this.getAccessToken();
        },
    
        /**
         * Store access token
         */
        storeAccessToken (tokenObject) {
            tokenObject.expires_at = moment().add(tokenObject.expires_in, 'seconds').format();
            
            this.$ls.set('token', tokenObject);
        },
    
        /**
         * Remove access token
         */
        removeAccessToken () {
            this.$ls.remove('token');
        },
    },
}