import Request from './Request';
import config from '../../config';

export default class UserServiceRequest extends Request {
    /**
     * Constructor
     */
    constructor() {
        super(config.api.userService);
    }
}
