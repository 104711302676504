<template>
  <div class="cookies-modal fixed bottom-0 bg-white h-full tablet:h-auto w-full z-30 px-5 laptop:px-15 py-4 laptop:py-9.75 bg-black-70 tablet:bg-white flex tablet:block items-center z-9999">
    <div class="tablet:flex  bg-white tablet:bg-transparent py-5 tablet:py-0 rounded-4">
      <div>
        <heading4 class="flex mb-3 justify-center tablet:justify-start">
          {{ $trans('portal.messages.personal_settings.cookies_policy_title') }}
        </heading4>

        <page-text class="mr-5 px-3 tablet:px-0">
          {{ $trans('portal.messages.personal_settings.cookie_policy_description') }}
        </page-text>

        <div
          v-if="isManageCookies"
          class="flex flex-col tablet:flex-row mt-6 pl-9 tablet:pl-0"
        >
          <!-- A N A L Y T I C A L -->
          <div class="flex items-center">
            <Switcher
              v-model="cookies.analytical"
              enabled-label-text=""
              disabled-label-text=""
            />

            <span>{{ $trans('core.labels.analytical') }}</span>
          </div>
        </div>
      </div>

      <!-- A C T I O N S  B T N S -->
      <div
        class="laptop:ml-12 flex tablet:block flex-row-reverse px-3 tablet:px-0 mt-9 tablet:mt-0 laptop:min-w-250 flex-wrap"
      >
        <!-- A C C E P T  B T N -->
        <button-primary
          class="w-full uppercase"
          :loading="isProcessing"
          :disabled="isProcessing"
          @click="acceptCookies()"
        >
          {{ acceptCookiesLabel }}
        </button-primary>

        <!-- M A N A G E  C O O K I E S  B T N -->
        <button-secondary
          :loading="isProcessingFlags.rejectAll"
          class="w-full manage-cookies uppercase tablet:mt-5 mt-3"
          @click.native="manageCookies"
        >
          {{ manageCookiesLabel }}
        </button-secondary>
      </div>
    </div>
  </div>
</template>

<script>
import PageText from 'Portal/components/text/PageText';
import ButtonPrimary from 'Portal/components/buttons/ButtonPrimary';
import ButtonSecondary from 'Portal/components/buttons/ButtonSecondary';
import Heading4 from 'Portal/components/text/Heading4';
import Switcher from 'Portal/components/switchers/Switcher';

import { mapActions, mapState } from 'vuex';
import Preferences from "Core/models/Preferences";
import Processable from "Core/mixins/Processable";

const cookieValue = (isRejectedAll, value) => isRejectedAll ? false : value;

export default {
  name: 'CookiesModal',

  components: {
    PageText,
    ButtonPrimary,
    ButtonSecondary,
    Heading4,
    Switcher,
  },

  mixins: [
    Processable,
  ],

  data() {
    return {
      cookies: {
        obligated: true,
        functional: false,
        analytical: true,
      },
      isManageCookies: false,
    };
  },

  /* COMPUTED */
  computed: {
    ...mapState('core/preferences', [
      'cookiesAccepted'
    ]),

    acceptCookiesLabel () {
      return !this.isManageCookies ?
        this.$trans('core.labels.accept_all') :
        this.$trans('core.labels.save_my_choises');
    },

    manageCookiesLabel () {
      return this.isManageCookies ?
        this.$trans('core.labels.reject_all') :
        this.$trans('core.labels.manage_cookies');
    }
  },

  /* METHODS */
  methods: {
    ...mapActions('core/preferences', [
      'savePreferences',
    ]),

    acceptCookies (isRejectedAll = false, processingKey = '') {
      this.enableProcessing(processingKey);

      const payload = [
        { name: Preferences.COOKIE_USAGE_ANALYTICAL, value: cookieValue(isRejectedAll, this.cookies.analytical) },
        { name: Preferences.COOKIE_USAGE_FUNCTIONAL, value: cookieValue(isRejectedAll, this.cookies.functional) },
        { name: Preferences.COOKIE_USAGE_OBLIGATED, value: cookieValue(isRejectedAll, this.cookies.obligated) },
        { name: Preferences.COOKIE_USAGE_ACCEPTED, value: !this.cookiesAccepted },
      ];

      this.savePreferences(payload).finally(() => this.disableProcessing(processingKey));
    },

    manageCookies () {
      if (!this.isManageCookies) {
        this.enableManageCookies();
      } else {
        this.rejectAllCookies();
      }
    },

    enableManageCookies () {
      this.isManageCookies = true;
    },

    rejectAllCookies () {
      this.acceptCookies(true, 'rejectAll');
    }
  },
}
</script>

<style scoped lang="scss">
  .cookies-modal {
    .el-button {
      margin-left: 0 !important;
    }
  }
</style>
