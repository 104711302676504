import snakeCase from 'lodash/snakeCase';
import { interpolate } from 'Core/utils/string';
import config from 'Core/config';

/**
 * This class handles translations.
 */
export default class Translator {
    /**
     * Constructor.
     *
     * @param translations
     * @param locale
     */
    constructor (translations, locale) {
        this.translations = translations;
        this.locale = locale;
    }

    /**
     * The trans function requires a key and if you have any replacement keys
     * you can give them as the second param that needs to be an object.
     *
     * @param key
     * @param replace
     * @returns {string}
     */
    trans (key, replace = {}) {
        let string = this.get(key);

        if (! string) {
            return key;
        }

        return this.replace(string, replace);
    }

    /**
     * Find the translation by its key.
     *
     * @param key
     */
    get (key) {
        const result = this.getByLocale(this.locale, key);

        if (! result) {
            return this.getByLocale(config.fallbackLocale, key);
        }

        return result;
    }

    /**
     * Get translation by locale
     *
     * @param locale
     * @param key
     *
     * @return {*}
     */
    getByLocale (locale, key) {
        let result = this.translations[locale];

        key.split('.').map(snakeCase).forEach(name => {
            if (result && result[name]) {
                result = result[name];
            } else {
                result = null;
            }
        });

        return result;
    }

    /**
     * Replace all the parameters.
     *
     * @param string
     * @param replace
     */
    replace (string, replace) {
        // Check if there are any replacements
        if (typeof replace === 'object' && Object.keys(replace).length > 0) {
            return interpolate(string, replace);
        }

        return string;
    }

    /**
     * Set new locale
     *
     * @param locale
     */
    setLocale(locale){
        this.locale = locale
    }
}
