import { clientGateway } from 'Core/api/ClientGateway';
import { createAsyncAction, createType, createAsyncState, createAsyncMutation} from 'Core/utils/store';

/**
 * Types
 */
const types = {
    GET_CLIENT: createType('GET_CLIENT'),
    SET_CLIENT: 'SET_CLIENT',
};

/**
 * Vuex state
 */
const state = {
    ...createAsyncState([
        types.GET_CLIENT,
    ]),

    client: null,
};

/**
 * Vuex getters
 */
const getters = {
    /**
     * Client getter
     *
     * @param state
     * @return {any}
     */
    client (state) {
        return state.client;
    },
};

/**
 * Vuex actions
 */
const actions = {
    /**
     * Get client of authenticated user
     *
     * @return {Promise<*>}
     */
    getClient: createAsyncAction(types.GET_CLIENT, () => {
        return clientGateway.getClient();
    }),
};

/**
 * Mutations.
 */
const mutations = {
    /**
     * Get client mutation
     */
    ...createAsyncMutation(types.GET_CLIENT, {
        success (state, { data }) {
            state.client = data.data;
        }
    }),

  [types.SET_CLIENT] (state, client) {
    state.client = client;
  }, 
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
