<template>
  <el-button
    class="button-secondary"
    :style="cssVariables"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-button>
</template>

<script>
    import BaseButton from './BaseButton';

    export default {
        /**
         * Component name
         */
        name: "ButtonSecondary",

        /**
         * Extend base component
         */
        extends: BaseButton,

        /**
         * Computed properties
         */
        computed: {
            /**
             * Override in ur inherit components
             */
            definition () {
                return this.styleGuideSchema.getSecondaryButtonDefinition();
            },
        },
    }
</script>
