import auth from '../../../../core/router/middleware/auth';


const background = () => import('Portal/components/miscs/Wrap');

export default [
    {
        path: '/request-account',
        name: 'portal.request-account',
        component: () => import('../../pages/auth/request-account/FirstLayoutRequestAccountPage'),
    },
    {
        path: '/logout',
        name: 'portal.logout',
        components: {
            background,
            default: () => import('../../pages/auth/logout/LogoutPage'),
        },
        meta: {
            middleware: { attach: [ auth ] },
        }
    },
]