<template>
  <div class="w-full h-screen flex items-center justify-center text-center">
    <div>
      <div class="lds-dual-ring" />
      <h1 class="font-lg">
        Loading portal...
      </h1>
      <p>Stand by...</p>
    </div>
  </div>
</template>

<script>
    export default {
        name: "TemplateLoader"
    }
</script>

<style type="text/scss" lang="scss">
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #1a2021;
    border-radius: 50%;
    border-color: #1a2021 transparent #1a2021 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>