import axios from 'axios';
import { HttpStatus } from 'Core/utils/http';
import config from 'Core/config';
import ExchangeTokenInterceptor from "Core/api/interceptors/ExchangeTokenInterceptor";

export default class UnauthenticatedInterceptor extends ExchangeTokenInterceptor {
    /**
     * Constructor
     *
     * @param context
     */
    constructor (context) {
        super(context);
        
        this.isFailed = false;
    }
    
    /**
     * Unauthenticated interceptor
     *
     * @param error
     */
    onRejected (error) {
        /*
         * In case the error comes from the refresh token
         * request we should reject
         */
        if (this.isRefreshAccessTokenRequestError(error)) {
            return Promise.reject(error);
        }
    
        /*
         * Here we need to check whether an error
         * can be handled by this interceptor
         */
        if (! this.canHandle(error)) {
            return Promise.reject(error);
        }
    
        /*
         * If endpoint is marked as ignored skip it
         */
        if (this.isIgnoredEndpoint(error.response.config)) {
            return Promise.reject(error);
        }
        
        /*
         * In case the token once has been failed to exchanged
         * we should also reject
         */
        if (this.isFailed) {
            return Promise.reject(error);
        }
        
        /*
         * In case the refresh token request is pending
         * at this moment retry the current request
         * once it is done
         */
        if (this.pendingRefreshRequest) {
            return this.handleRequestWhenPending(error.config);
        }
        
        return this.createPendingRefreshTokenRequest(error.config);
    }
    
    /**
     * Whether interceptor can handle error
     *
     * @param error
     * @return {boolean}
     */
    canHandle (error) {
        return error &&
            error.config &&
            error.response &&
            error.response.status === HttpStatus.UNAUTHORIZED;
    }
    
    /**
     * Is is error come from refresh access token request
     *
     * @param error
     * @return {boolean}
     */
    isRefreshAccessTokenRequestError (error) {
        const errorConfig = error?.config;
        
        return errorConfig && errorConfig.url === config.refreshToken.fullUrl;
    }
    
    /**
     * Retry request with token
     *
     * @param config
     * @return {function(*): Promise<*>}
     */
    retryRequest (config) {
        return (token) => {
            config.headers['Authorization'] = token;
            
            return axios.request(config);
        };
    }
}
