import BaseGateway from '../../../core/api/BaseGateway';
import UserServiceRequest from '../../../core/api/requests/UserServiceRequest';

/**
 * User gateway
 */
export default class PersonAddressesGateway extends BaseGateway {
    /**
     * Get addresses
     *
     * @return {Promise<*>}
     * @param includes
     */
    getAddresses (includes = []) {
        return this.request()
            .withIncludes(includes)
            .withParams({
                limit: 100
            })
            .get('me/person/addresses');
    }

    /**
     * New user request object
     *
     * @return {UserServiceRequest}
     */
    makeRequest() {
        return new UserServiceRequest();
    }
}

/**
 * Instance of gateway
 *
 * @type {PersonAddressesGateway}
 */
export const personAddressesGateway = new PersonAddressesGateway();
