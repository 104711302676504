import Request from './Request';
import config from '../../config/index';

export default class OfferServiceRequestV2 extends Request {
    /**
     * Constructor
     */
    constructor() {
        super(config.api.offerServiceV2);
    }
}
