import FontsLoader from "Portal/services/fonts/FontsLoader";

/**
 * Load fonts
 *
 * @param fonts
 * @return {Promise<*>}
 */
export default (fonts) => {
    const loader = new FontsLoader(fonts);
    
    return loader.load();
}