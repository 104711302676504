<template>
  <div
    class="logo-wrap logo-wrap__secondary"
    :style="cssVariables"
  >
    <el-image
      :src="url"
      class="logo"
      fit="contain"
    />
  </div>
</template>

<script>
    import BaseLogo from './BaseLogo';

    export default {
        /**
         * Component name
         */
        name: "SecondaryLogo",

        /**
         * Extend base component
         */
        extends: BaseLogo,

        props: {
            isFooterLogo: {
                type: Boolean,
                default: false
            }
        },

        /**
         * Computed properties
         */
        computed: {
            isFooterLogoUrl () {
                const footerLogoStyleGuideSchema = this.styleGuideSchema.get('secondary-footer-logo');
                return !!footerLogoStyleGuideSchema.getUrl();
            },

            /**
             * Override in ur inherit components
             *
             * @return {StyleDefinition|null}
             */
            definition () {
                if (!this.isFooterLogo || !this.isFooterLogoUrl) return this.styleGuideSchema.get('secondary-logo');

                return this.styleGuideSchema.get('secondary-footer-logo');
            },

            /**
             * Css variables
             *
             * @return {*[]}
             */
            cssVariables () {
                return [
                    this.definition.getFlexPositioning(),
                ];
            },
        },
    }
</script>

<style scoped>

</style>