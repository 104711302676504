import { mapGetters } from 'vuex';

export default {
    /**
     * Mixin computed
     */
    computed: {
        /**
         * Vuex getters
         */
        ...mapGetters('portal/template', [
            'template',
        ]),
    },
}