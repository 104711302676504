<template>
  <div class="flex items-center">
    <el-switch
      v-bind="$attrs"
      :value="value"
      class="switcher"
      :style="cssVariables"
      v-on="$listeners"
      @input="onInput"
    />
    <heading3 class="uppercase pl-3">
      {{ stateText }}
    </heading3>
  </div>
</template>

<script>
    import BaseSwitcher from './BaseSwitcher';
    import trans from '../../../../core/services/translations/trans';
    import Heading3 from '../text/Heading3';
    import WithStyleguideSchema from 'Portal/mixins/WithStyleguideSchema';

    export default {
        /**
         * Component name
         */
        name: "Switcher",

        /**
         * Components
         */
        components: {
            Heading3
        },

        /**
         * Extend base component
         */
        extends: BaseSwitcher,

        /**
         * Mixins
         */
        mixins: [
            WithStyleguideSchema,
        ],

        /**
         * Component properties
         */
        props: {
            /**
             * Disable label
             */
            withoutLabel: {
                type: Boolean,
                default () {
                    return false;
                }
            },

            /**
             * Label when enabled
             */
            enabledLabelText: {
                type: String,
                default () {
                    return trans('core.actions.yes');
                }
            },

            /**
             * Label when disabled
             */
            disabledLabelText: {
                type: String,
                default () {
                    return trans('core.actions.no');
                }
            },
        },

        /**
         * Computed properties
         */
        computed: {
            /**
             * Text depending of the state of a switcher
             *
             * @return {string|null}
             */
            stateText () {
                if (this.withoutLabel) {
                    return null;
                }

                return this.value ? this.enabledLabelText : this.disabledLabelText;
            },

            /**
             * Style definitions
             *
             * @return {{hover: (StyleDefinition|null), disable: (StyleDefinition|null), active: (StyleDefinition|null)}}
             */
            definition () {
                return this.styleGuideSchema.getPrimaryButtonDefinition();
            },

            /**
             * Css variables
             *
             * @return {*[]}
             */
            cssVariables () {
                if (! this.definition) {
                    return [];
                }

                return [
                    this.definition.active.getBackgroundColor(),
                    this.definition.disable.getBackgroundColor(),
                ];
            },
        }
    }
</script>

<style scoped>

</style>