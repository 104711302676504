export default class PageType {
    static get CUSTOM () {
        return 'custom';
    }

    static get CLIENT_FORMS () {
        return 'client-forms';
    }

    static get SIGN_OFFER () {
        return 'sign-offer';
    }

    static get VIEW_SELECT_OFFER () {
        return 'view-select-offer';
    }

    static get COMPARE_OFFERS () {
        return 'compare-offers';
    }
  
    static get COMPARE_OFFERS_DROPDOWN () {
        return 'compare-offers-dropdown';
    }

    static get LOGIN () {
        return 'login';
    }
    
    static get PLANNING () {
        return 'planning';
    }

    static get FORGOT_PASSWORD () {
        return 'forgot-password';
    }

    static get RESET_PASSWORD () {
        return 'reset-password';
    }
    
    static get HOME () {
        return 'home';
    }

    static get PERSONAL_CABINET () {
        return 'personal-cabinet';
    }
}