import { extend } from 'vee-validate';
import { passwordValidationSchema, passwordRules } from '../services/PasswordValidator';
import PhoneAwesome from 'awesome-phonenumber';

/**
 * The password validation rule
 */
extend('password', (value, name) => {
    const result = passwordValidationSchema.validate(value, { list: true });

    // In case the validation rule is empty is means all rules has been passed
    if (result.length === 0) {
        return true;
    }

    if (result.includes('min')) {
        return trans('core.validation.password.min', passwordRules);
    }

    if (result.includes('digits')) {
        return trans('core.validation.password.digits');
    }

    return true;
});

/**
 * Required rule for checkboxes
 */
extend('is_checked', {
    validate (value) {
        if (value) {
            return true;
        }

        return trans('core.validation.is_checked');
    },

    computesRequired: true,
});

/**
 * Rule for phone number validation
 */
extend('phone', {
    validate (value) {
        const isValid = PhoneAwesome(value).isValid();
        
        if (isValid) {
            return true;
        }
        
        return trans('core.validation.phone');
    },
});