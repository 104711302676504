import {createAsyncAction, createAsyncMutation, createAsyncState, createType} from 'Core/utils/store';
import {requestTypesGateway} from 'Portal/api/RequestTypesGateway';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

/**
 * Types.
 */
const types = {
  SET_REQUEST_TYPES: 'SET_REQUEST_TYPES',
};

/**
 * State
 *
 * @type {{}}
 */
const state = {
  types: [],
};

/**
 * Getters.
 *
 * @type {{}}
 */
const getters = {
  /**
   * Getter for request types
   *
   * @param state
   * @return {*}
   */
  types(state) {
    return state.types;
  },
  
  /**
   * Get phases of the type
   *
   * @param state
   * @param getters
   *
   * @return {function(*): (*|[])}
   */
  getTypePhases(state, getters) {
    return (id) => {
      const type = getters.types.find(type => type.product_id === id);
      
      if (type) {
        return sortBy(type.phases, 'order');
      }
      
      return [];
    };
  },
  
  /**
   * Get classification by request type
   *
   * @param state
   * @param getters
   * @return {function(*, *): *}
   */
  getClassificationByType(state, getters) {
    return (alias, requestTypeId) => {
      const classifications = find(getters.types, ['product_id', requestTypeId])?.classifications || [];
      
      return find(classifications, ['type', alias]);
    };
  },
};

/**
 * Actions.
 */
const actions = {
  /**
   * Load request types
   */
  setRequestTypes({commit}, requestTypes) {
    commit(types.SET_REQUEST_TYPES, requestTypes);
  },
};

/**
 * Mutations.
 *
 * @type {{}}
 */
const mutations = {
  /**
   * Load template public pages mutation
   */
  [types.SET_REQUEST_TYPES](state, types) {
    state.types = types;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};