import BaseGateway from './BaseGateway';
import OfferServiceRequest from 'Core/api/requests/OfferServiceRequest';
import OfferServiceRequestV2 from 'Core/api/requests/OfferServiceRequestV2';
import get from 'lodash/get';

const extractResponse = (path = 'data.data') => (response) => get(response, path);

/**
 * OfferGateway
 */
export default class OfferGateway extends BaseGateway {
    getOffersByRequestId(id, isPublished = true) {
        return this.request()
          .withIncludes(['settings', 'price', 'products', 'template'])
          .withParams({ request_id: id, is_published: +isPublished })
          .get(`offers`)
          .then(extractResponse());
    }

    getOfferByRequestIdLegacy(id) {
        return this.usingRequest(new OfferServiceRequest).get(`requests/${id}/offers`).then(extractResponse());
    }

    updateOffer(id, body) {
        return this.usingRequest(new OfferServiceRequest)
        .withBody(body)
        .post(`offers/${id}`)
        .then(extractResponse());
    }

    getPublishedOffers(id) {
        return  this.usingRequest(new OfferServiceRequest).get(`requests/${id}/published-offers`).then(extractResponse());
    }
  
    /**
     * @deprecated
     */
    updateOfferLegacy(id, body, requestId) {
        return this.usingRequest(new OfferServiceRequest)
            .withBody(body)
            .withParams({
                request_id: requestId
            })
            .put(`offers/${id}`).then(extractResponse());
    }

    getOfferById(id) {
        return this.request()
            .withIncludes(['products', 'template'])
            .get(`offers/${id}`).then(extractResponse());
    }

    approve(id, status_id) {
        return this.request()
        .withBody({ status_id })
        .put(`offers/${id}/approve`)
        .then(extractResponse('data'));
    }

    reject(id, body) {
        return this.usingRequest(new OfferServiceRequest)
        .withBody(body)
        .put(`offers/${id}/reject`)
        .then(extractResponse('data'));
    }

    sign (id, body) {
        return this.usingRequest(new OfferServiceRequest)
        .withBody(body)
        .put(`offers/${id}/sign`)
        .then(extractResponse('data'));
    }

    /**
     * Make new offer service request
     *
     * @return {OfferServiceRequestV2}
     */
    makeRequest() {
        return new OfferServiceRequestV2()
    }
}

/**
 * Instance of gateway
 *
 * @type {OfferGateway}
 */
export const offerGateway = new OfferGateway();
