import Vue from 'vue';
import App from './App.vue';
import 'Core/bootstrap';
import './styles/main.scss';
import router from 'Core/router';
import store from 'Core/store';
import InteractsWithAccessToken from "Core/mixins/InteractsWithAccessToken";
import initializeHttpInterceptors from 'Core/api/interceptors';

Vue.config.productionTip = false;

new Vue({
    /**
     * Mixins
     */
    mixins: [
        InteractsWithAccessToken,
    ],

    /**
     * Before created hook
     */
    beforeCreate () {
        initializeHttpInterceptors(this);
    },

    /**
     * Render func
     *
     * @param h
     * @return {*}
     */
    render: h => h(App),

    /**
     * Vue router.
     */
    router,

    /**
     * Vuex.
     */
    store
}).$mount('#app');
