import en from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import nl from "element-ui/lib/locale/lang/nl";
import translator from "Core/services/translations/instance";
import Vue from "vue";
import ElementUI from "element-ui";

Vue.use(ElementUI);

const lang = {
    en,
    nl,
};

// configure language
locale.use(lang[translator.locale]);
