export default class Query {
    /**
     * Constructor.
     */
    constructor (url = null) {
        this.instance = new URL(url !== null ? url : window.location);
    }

    /**
     * Return the instance as a url string.
     *
     * @returns {string}
     */
    toString () {
        return this.instance.toString();
    }

    /**
     * Check if the URL contains the given paramater key.
     *
     * @param key
     * @returns {boolean}
     */
    has (key) {
        return this.instance.searchParams.has(key) === true;
    }

    /**
     * Check if the URL misses the given paramater key.
     *
     * @param key
     * @returns {boolean}
     */
    missing (key) {
        return this.instance.searchParams.has(key) !== true;
    }

    /**
     * Get the value for the given paramater key.
     *
     * @param key
     * @param callback
     * @returns {string}
     */
    get (key, callback = null) {
        let value = this.instance.searchParams.get(key);

        if (typeof callback === 'function') {
            value = callback(value);
        }

        return value;
    }

    /**
     * Get all paramaters from the URL.
     *
     * @returns {object}
     */
    getAll () {
        let params = {};

        for (let pair of this.instance.searchParams.entries()) {
            params[pair[0]] = pair[1];
        }

        return params;
    }

    /**
     * Add the name with associated value to the search params.
     *
     * @param name
     * @param value
     * @returns {Query}
     */
    add (name, value) {
        this.instance.searchParams.append(name, value);

        return this;
    }

    /**
     * Add multiple params to the search params
     * 
     * @param params
     * @returns {Query}
     */
    addParams (params = {}) {
        for (let name in params) {
            if (params.hasOwnProperty(name)) {
                this.add(name, params[name]);
            }
        }

        return this;
    }
}