import { render, staticRenderFns } from "./PageText.vue?vue&type=template&id=939e2514&scoped=true&"
import script from "./PageText.vue?vue&type=script&lang=js&"
export * from "./PageText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939e2514",
  null
  
)

export default component.exports