<template>
  <router-link
    :to="to"
    exact-active-class="header-menu__item--active"
    class="header-menu__item"
    :style="cssVariables"
  >
    <HeaderText class="header-menu__item-title">
      {{ title }}
    </HeaderText>
  </router-link>
</template>

<script>
    import Model from 'Core/mixins/Model';
    import WithCurrentPage from '../../mixins/WithCurrentPage';
    import HeaderText from 'Portal/components/text/HeaderText';
    import BaseButton from '../buttons/BaseButton';

    export default {
        /**
         * Component name
         */
        name: "HeaderMenuItem",

        /**
         * Components
         */
        components: {
            HeaderText,
        },

        extends: BaseButton,

        /**
         * Component mixins
         */
        mixins: [
            Model,
            WithCurrentPage,
        ],

        /**
         * Computed properties
         */
        computed: {
            /**
             * The title of the menu item
             *
             * @return {string}
             */
            title () {
                const title = this.value.settings_map['browser-title'];

                if (title) {
                    return title;
                }

                return 'Unregistered title';
            },

            /**
             * Is link active
             */
            isActive () {
                return this.value.id === this.currentPage.id;
            },
            
            /**
             * Router link to
             */
            to () {
                const { route, routeParams } = this.value;
                
                return {
                    ...route,
                    params: routeParams
                };
            },

            buttonStates () {
                return ['hover', 'active'];
            },

            definition () {
                return this.styleGuideSchema.getHeaderMenuButtonDefinition();
            },
        },
    }
</script>

<style type="text/scss" lang="scss" scoped>
    @import "src/styles/module/portal/variables";

    @mixin header-menu-item ($prefix-name) {
      color: var(--sg-#{$prefix-name}-text-color);
      background-color: var(--sg-#{$prefix-name}-bg-color, #e5e8ec);
      border: solid 1px var(--sg-#{$prefix-name}-border-color, 'transparent');
      font-family: var(--sg-#{$prefix-name}-font-family, 'Open sans');
      font-size: var(--sg-#{$prefix-name}-font-size, 14px);
      font-weight: var(--sg-#{$prefix-name}-font-weight, 400);
      box-shadow: 0 4px 4px var(--sg-#{$prefix-name}-shadow-color);
      border-radius: var(--sg-#{$prefix-name}-border-radius, 9999px);
    }

    .header-menu__item {
        height: $laptop-header-height;
        @apply cursor-pointer truncate flex items-center justify-center;
        
        margin: 0 15px;

        @media screen and (min-width: 1920px) {
            margin: 0 35px;
        }

        &-title {
            @apply py-1 px-3;
            border: solid 1px transparent;
            
            &:hover {
              @include header-menu-item('hover-header-menu-button');
            }
        }

        &--active {
          & > * {
            @include header-menu-item('header-menu-button');

            &:hover {
              @include header-menu-item('hover-header-menu-button');
            }
          }
        }
    }
</style>