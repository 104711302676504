import get from 'lodash/get';
import StyleDefinition from './StyleDefinition';

export default class StylesSchema {
    constructor(schema = {}) {
        this.setSchema(schema);
    }

    /**
     * Set schema
     *
     * @param schema
     */
    setSchema (schema) {
        this.schema = schema;
    }

    /**
     * Get the style definition object
     *
     * @param path
     *
     * @return {StyleDefinition|null}
     */
    get (path) {
        const definitionOptions = get(this.schema, path, null);

        if (definitionOptions) {
            const definition = new StyleDefinition(definitionOptions);
            definition.setLayouts(this.getLayoutsOptions());

            return definition;
        }

        return new StyleDefinition();
    }

    /**
     * Get layout options
     *
     * @return {{layout1: *, layout2: *, layout3: *}}
     */
    getLayoutsOptions () {
        return {
            'l-1': this.schema['l-1'],
            'l-2': this.schema['l-2'],
            'l-3': this.schema['l-3'],
        };
    }

    /**
     * Get definition for primary buttons
     *
     * @return {{hover: (StyleDefinition|null), disable: (StyleDefinition|null), active: (StyleDefinition|null)}}
     */
    getPrimaryButtonDefinition () {
        const layout = {
            layout: this._getDefinitionValue('primary-button.layout'),
        };

        return {
            active: this.get('primary-button.active').mergeOptions(layout),
            hover: this.get('primary-button.hover').setPrefix('hover'),
            disable: this.get('primary-button.disable').setPrefix('disable'),
        };
    }

    getHeaderMenuButtonDefinition () {
        const layout = {
            layout: this._getDefinitionValue('primary-button.layout'),
        };

        return {
            active: this.get('header-menu-button.active').setPrefix('header-menu-button').mergeOptions(layout),
            hover: this.get('header-menu-button.hover').setPrefix('hover-header-menu-button').mergeOptions(layout),
        };
    }

    /**
     * Get definition for secondary buttons
     *
     * @return {{hover: (StyleDefinition|null), disable: (StyleDefinition|null), active: (StyleDefinition|null)}}
     */
    getSecondaryButtonDefinition () {
        const layout = {
            layout: this._getDefinitionValue('secondary-button.layout'),
        };

        return {
            active: this.get('secondary-button.active').mergeOptions(layout),
            hover: this.get('secondary-button.hover').setPrefix('hover'),
            disable: this.get('secondary-button.disable').setPrefix('disable'),
        };
    }

    getPhaseButtonDefinition () {
        const layout = {
            layout: this._getDefinitionValue('phase-button.layout'),
        };

        return {
            available: this.get('phase-button.available').setPrefix('available').mergeOptions(layout),
            active: this.get('phase-button.active').setPrefix('active').mergeOptions(layout),
            disable: this.get('phase-button.disable').setPrefix('disable').mergeOptions(layout),
        };
    }

    getSignButtonDefinition () {
        const layout = {
            layout: this._getDefinitionValue('primary-button.layout'),
        };

        return {
            active: this.get('sign-offer-button.active').mergeOptions(layout),
            hover: this.get('sign-offer-button.hover').setPrefix('hover'),
            disable: this.get('sign-offer-button.disable').setPrefix('disable'),
        };
    }

    /**
     * Get the value of definition
     *
     * @param path
     * @return {*}
     * @private
     */
    _getDefinitionValue (path) {
        return get(this.schema, path);
    }
}