export default {
    /**
     * Mixin data
     *
     * @returns {{isProcessing: boolean}}
     */
    data () {
        return {
            /*
             * Is action is being processed
             */
            isProcessingFlags: {
                default: false,
            },
        }
    },

    /**
     * Computed
     */
    computed: {
        /**
         * Getter for processing
         *
         * @return {boolean}
         */
        isProcessing () {
            return this.isProcessingFlags.default;
        }
    },

    /**
     * Mixin methods
     */
    methods: {
        /**
         * Setter for processing flag
         *
         * @param key
         * @param flag
         */
        setIsProcessing (key = 'default', flag) {
            this.$set(this.isProcessingFlags, key, flag);
        },

        /**
         * Enable processing
         */
        enableProcessing (key = 'default') {
            this.setIsProcessing(key, true);
        },

        /**
         * Disable processing
         */
        disableProcessing (key = 'default') {
            this.setIsProcessing(key, false);
        }
    }
}
