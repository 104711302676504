import {authGateway} from "Core/api/AuthGateway";
import BaseGateway from "Core/api/BaseGateway";
import store from "Core/store";
import config from "Core/config";
import moment from 'moment';
import {redirect} from "Core/utils/dom";
import Query from "Core/services/query/Query";

/**
 * Exchange access token 10 seconds before it actually expires
 *
 * @type {number}
 */
const EXCHANGE_GAP = 10;

export default class ExchangeTokenInterceptor {
    /**
     * Constructor
     *
     * @param context
     */
    constructor (context) {
        this.context = context;
        this.successCallbacks = [];
        this.failCallbacks = [];
        this.pendingRefreshRequest = null;
        
        this.exceptEndpoints = ['/login'];
    }
    
    /**
     * Returns true when token needs to be exchanged
     *
     * @return {boolean}
     */
    isTokenExpired () {
        const token = this.context.getAccessToken();
        
        if (! token || !token?.expires_at) {
            return false;
        }
        
        const expiredAt = moment(token.expires_at).subtract(EXCHANGE_GAP, 'seconds');
        
        return moment().isAfter(expiredAt);
    }
    
    isIgnoredEndpoint ({ url }) {
        return this.exceptEndpoints.some(path => url.includes(path));
    }
    
    /**
     * Handle function
     *
     * @param request
     * @return {Promise<unknown>|PromiseLike<T>|Promise<any>|Q.Promise<T>|PromiseLike<T>|Promise<T>}
     */
    onFulfilled (request) {
        if (
            ! this.isTokenExpired() ||
            this.isRefreshAccessTokenRequestError(request) ||
            this.isIgnoredEndpoint(request)
        ) {
            return Promise.resolve(request);
        }
        
        if (this.pendingRefreshRequest) {
            return this.handleRequestWhenPending(request);
        }
        
        return this.createPendingRefreshTokenRequest(request);
    }
    
    /**
     * Refreshes access token
     *
     * @return {Promise<*>}
     */
    refreshAccessToken () {
        const tokenData = this.context.getAccessToken();
        
        return authGateway.refresh(tokenData?.refresh_token, tokenData?.access_token).then(({ data }) => {
            const token = `${data.token_type} ${data.access_token}`;
            
            BaseGateway.addDefaultHeader('Authorization', token);
            this.context.storeAccessToken(data);
            
            this.successCallbacks.forEach((cb) => cb(token));
            
            return token;
        });
    }
    
    /**
     * Handle error when refresh token failed to refresh
     *
     * @return {*}
     */
    handleFailedAccessTokenExchange () {
        this.context.$ls.remove('token');
        this.context.$ls.remove('user');
        store.dispatch('core/user/clearAuthenticatedUser');

        this.failCallbacks.forEach((cb) => cb());
    
        this.isFailed = true;
  
        redirect('/login');
    }
    
    /**
     * Is is request come from refresh access token request
     *
     * @param request
     * @return {*|boolean}
     */
    isRefreshAccessTokenRequestError (request) {
        return request.url === config.refreshToken.fullUrl;
    }
    
    /**
     * Retry request with token
     *
     * @param config
     * @return {*}
     */
    retryRequest (config) {
        return (token) => {
            config.headers['Authorization'] = token;
            
            return config;
        };
    }
    
    /**
     * Handle logic when refresh token finished to exchange
     */
    handleFinish () {
        this.pendingRefreshRequest = null;
        this.successCallbacks = [];
        this.failCallbacks = [];
    }
    
    /**
     * Start refreshing token
     *
     * @param config
     * @return {PromiseLike<T> | Promise<any> | Q.Promise<T> | PromiseLike<T> | Promise<T>}
     */
    createPendingRefreshTokenRequest (config) {
        this.pendingRefreshRequest = this.refreshAccessToken()
            .catch((e) => this.handleFailedAccessTokenExchange(e))
            .finally(() => this.handleFinish());
    
        return this.pendingRefreshRequest.then(this.retryRequest(config));
    }
    
    /**
     * Handle request when refresh token is still exchanging (http request in progress)
     */
    handleRequestWhenPending (config) {
        return new Promise((resolve, reject) => {
            this.successCallbacks.push(resolve);
            this.failCallbacks.push(reject);
        }).then(this.retryRequest(config));
    };
}
