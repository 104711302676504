import BaseGateway from './BaseGateway';
import WebPushRequest from 'Core/api/requests/WebPushRequest';

export default class WebPushGateway extends BaseGateway {
	sendSubscription (data) {
		return this.request()
			.withBody(data)
			.post('save-subscription')
	}

	makeRequest() {
		return new WebPushRequest()
	}
}

export const webPushGateway = new WebPushGateway();
